import { ChangeEvent, useState, useCallback } from 'react';

export default function useInput(initialValue = {}) {
  const [inputVal, setInputVal] = useState<{ [key: string]: string }>(
    initialValue || {},
  );

  const changeInputVal = useCallback(
    (
      e: ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = e.target;

      setInputVal(inputVal => ({ ...inputVal, [name]: value }));
    },
    [],
  );

  const clickInputVal = useCallback((campName: string) => {
    setInputVal(inputVal => ({ ...inputVal, camp: campName }));
  }, []);

  const initializeInfo = (initial: { [key: string]: string }) => {
    setInputVal(initial);
  };

  const resetInput = (name: string) => {
    setInputVal({ [name]: '' });
  };

  return {
    inputVal,
    setInputVal,
    changeInputVal,
    resetInput,
    initializeInfo,
    clickInputVal,
  };
}
