import { FocusEvent, memo } from 'react';
import styled, { CSSProp } from 'styled-components';

interface Props {
  name: string;
  placeholder?: string;
  inputVal: { [key: string]: string } | string;
  changeInputVal: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  maxLength?: number;
  type?: React.HTMLInputTypeAttribute;
  isError?: boolean;
  isDisabled?: boolean;
  $width?: string;
  cssObject?: CSSProp;
}

const BasicInputOutLined = ({
  name,
  placeholder,
  inputVal,
  changeInputVal,
  onBlur,
  onKeyPress,
  maxLength,
  type = 'text',
  isError,
  isDisabled,
  $width,
  cssObject,
}: Props) => {
  return (
    <Input
      name={name}
      value={typeof inputVal === 'string' ? inputVal : inputVal[name]}
      placeholder={placeholder}
      onChange={changeInputVal}
      onBlur={onBlur}
      onKeyPress={onKeyPress}
      maxLength={maxLength}
      type={type}
      isError={isError}
      disabled={isDisabled}
      $width={$width}
      css={cssObject}
    />
  );
};

export default memo(BasicInputOutLined);

interface Style {
  isError?: boolean;
  $width?: string;
  css?: CSSProp;
}

const Input = styled.input<Style>`
  width: ${({ $width }) => $width || '100%'};
  height: 48px;
  padding: 12px;
  border: 1px solid
    ${({ theme, isError }) =>
      isError ? theme.newColors.red : theme.newColors.lightGrey2};

  border-radius: 8px;

  font-size: 1.4rem;
  font-family: 'SpoqaHanSans';
  font-weight: 500;
  line-height: 21px;
  color: ${({ theme }) => theme.newColors.black};

  cursor: auto;

  // iOS Safari에서 disabled 처리 시 기본값으로 opacity 0.4 적용되는 문제 해결용
  :disabled {
    border: 1px solid ${({ theme }) => theme.newColors.lightGrey2};
    background-color: ${({ theme }) => theme.newColors.background};
    opacity: 1;
  }

  &::placeholder {
    color: ${({ theme }) => theme.newColors.grey1};
  }

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &:-mos-input-placeholder {
    color: ${({ theme }) => theme.newColors.grey1};
  }

  /* input type number 일 때 화살표 삭제 */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${({ css }) => css}
`;
