import { memo } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useRoute from 'hooks/useRoute';
import { isMobile, share } from 'utils/share';
import { ReactComponent as Camfit } from 'static/icons/logos/camfit.svg';
import { ReactComponent as Share } from 'static/icons/subHeader/share.svg';
import { ReactComponent as Share24 } from 'static/icons/subHeader/share24new.svg';
import { ReactComponent as ShareBg } from 'static/icons/subHeader/shareBg.svg';
import { ReactComponent as BoldBack24 } from 'static/icons/subHeader/boldBack24.svg';
import { ReactComponent as BoldBackBg } from 'static/icons/subHeader/boldBackBg.svg';
import { ReactComponent as CloseIcon } from 'static/icons/close.svg';
import { ReactComponent as Menu24 } from 'static/icons/community/menu24.svg';
import { ReactComponent as Home } from 'static/icons/subHeader/home.svg';
import { ReactComponent as Bookmark } from 'static/icons/subHeader/bookmark.svg';
import { ReactComponent as BookmarkFilled } from 'static/icons/subHeader/bookmarkFilled.svg';
import { theme } from 'static/styles/theme';
import SpinLoader from 'widgets/SpinLoader';

interface Props {
  title: string;
  isCampTitle?: boolean;
  clickBackBtn?: () => void;
  url?: string;
  isWarning?: boolean;
  isVisible?: boolean;
  isCampingLog?: boolean;
  isBoardDetail?: boolean;
  openMenuModal?: () => void;
  isCommentDetail?: boolean;
  article?: boolean;
  isEvent?: boolean;
  useTitle?: boolean;
  isHomeBtn?: boolean;
  isMerged?: boolean;
  isProductQnaList?: boolean;
  clickBookmark?: () => void;
  isBookmark?: boolean;
  isCampDetail?: boolean;
  isBookmarkLoading?: boolean;
}

const SubHeader = ({
  title,
  isCampTitle,
  clickBackBtn,
  url,
  isWarning,
  isVisible,
  isCampingLog,
  isBoardDetail,
  isCommentDetail,
  openMenuModal,
  article,
  isEvent,
  isHomeBtn,
  isMerged,
  useTitle = false,
  isProductQnaList = false,
  clickBookmark,
  isBookmark,
  isCampDetail,
  isBookmarkLoading,
}: Props) => {
  const { goBack } = useRoute();

  return (
    <SubHeaderContainer
      fix={!!(isVisible && isCampTitle) || isMerged}
      basic={!isCampTitle}
      transparent={!!((isCampTitle && !isVisible) || article)}
      article={!!article}
    >
      {isWarning ? (
        <div className="close" onClick={clickBackBtn || goBack} aria-hidden>
          <CloseIcon fill="#13221C" />
        </div>
      ) : article ? (
        <div
          className={isCampTitle ? 'icon' : 'chevron'}
          onClick={clickBackBtn || goBack}
          aria-hidden
        >
          <BoldBackBg />
        </div>
      ) : isProductQnaList ? (
        // <div
        //   className="left-arrow-back"
        //   onClick={clickBackBtn || goBack}
        //   aria-hidden
        // >
        //   <LeftArrowBack />
        // </div>
        // 2022.05.16 우인님 요청사항 (화살표 기존 컴포넌트로 우선 통일하고, 추후 전체 변경)
        <div className="icon-24" onClick={clickBackBtn || goBack} aria-hidden>
          <BoldBack24 width={24} height={24} fill="#13221C" />
        </div>
      ) : (
        // 배포를 위해 급하게 서브헤더 왼쪽 상단 뒤로가기 버튼을 수정하였습니다. 나중에 정리 필요합니다. / ywroh / 2022.03.24
        <div className="icon-24" onClick={clickBackBtn || goBack} aria-hidden>
          {!isHomeBtn ? (
            <BoldBack24
              width={24}
              height={24}
              fill={isCampTitle && !isVisible ? 'white' : '#13221C'}
            />
          ) : (
            <Home />
          )}
        </div>
      )}
      {isEvent && (
        <Link to="/">
          <Home />
        </Link>
      )}
      {!article && (
        <Title
          center={!!url || isBoardDetail || isCommentDetail || isProductQnaList}
          isEvent={isEvent}
          isCampDetail={isCampDetail}
        >
          {(isCampTitle || isCampingLog) && !useTitle ? (
            <Link to="/">
              <Camfit
                fill={isVisible ? theme.newColors.primary : '#ffffff'}
                width="82"
                height="16"
              />
            </Link>
          ) : (
            title
          )}
        </Title>
      )}
      {url && clickBookmark ? (
        <BookmarkShareWrapper>
          {isBookmarkLoading ? (
            <SpinLoader scroll width="24px" margin="0 8px 0 0" />
          ) : (
            <div onClick={clickBookmark} aria-hidden>
              {isBookmark ? (
                isVisible ? (
                  <BookmarkFilled fill="#43c083" />
                ) : (
                  <BookmarkFilled fill="#ffffff" />
                )
              ) : isVisible ? (
                <Bookmark fill="#000000" />
              ) : (
                <Bookmark fill="#ffffff" />
              )}
            </div>
          )}

          <div onClick={share(title, url)} aria-hidden>
            {isMobile() ? (
              <Share24
                fill={isCampTitle && !isVisible ? '#ffffff' : '#050606'}
              />
            ) : (
              <CopyToClipboard text={url || ''}>
                <Share24
                  fill={isCampTitle && !isVisible ? '#ffffff' : '#050606'}
                />
              </CopyToClipboard>
            )}
          </div>
        </BookmarkShareWrapper>
      ) : url ? (
        <div className="icon">
          <div onClick={share(title, url)} aria-hidden>
            {isMobile() ? (
              article ? (
                <ShareBg />
              ) : (
                <Share
                  stroke={isCampTitle && !isVisible ? '#ffffff' : '#050606'}
                />
              )
            ) : (
              <CopyToClipboard text={url || ''}>
                {article ? (
                  <ShareBg />
                ) : (
                  <Share
                    stroke={isCampTitle && !isVisible ? '#ffffff' : '#050606'}
                  />
                )}
              </CopyToClipboard>
            )}
          </div>
        </div>
      ) : isBoardDetail || isCommentDetail ? (
        <div className="icon-menu">
          <Menu24
            width="24"
            height="24"
            fill="#050606"
            onClick={openMenuModal}
          />
        </div>
      ) : isProductQnaList ? (
        <div className="right-space" />
      ) : (
        ''
      )}
    </SubHeaderContainer>
  );
};

export default memo(SubHeader);

interface Style {
  center?: boolean;
  fix?: boolean;
  transparent?: boolean;
  basic?: boolean;
  article?: boolean;
  isEvent?: boolean;
  isCampDetail?: boolean;
}

const SubHeaderContainer = styled.header<Style>`
  position: ${props => (props.fix ? 'fixed' : props.basic ? '' : 'absolute')};
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 52px;
  max-width: 530px;
  width: 100%;
  background: ${({ theme, transparent }) =>
    transparent
      ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%)'
      : theme.colors.bw100};
  border-bottom: ${({ theme, transparent }) =>
    !transparent && `1px solid ${theme.colors.bw97}`};

  ${props =>
    props.fix &&
    `
    position: fixed;
    z-index: 1000;
    `}

  ${props =>
    props.article &&
    `
    justify-content: space-between;
    padding: 0 3px;
    background: none;
    `}

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    cursor: pointer;
  }

  .icon {
    height: 100%;
    cursor: pointer;
  }

  .icon-24 {
    margin: 14px 17px 14px 11px;
    /* height: 100%; */
    cursor: pointer;
  }

  .icon-menu {
    margin: 14px 6px 14px 22px;
    cursor: pointer;
  }

  .chevron {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 52px;
    cursor: pointer;
  }

  svg {
    display: block;
  }

  .left-arrow-back {
    margin: 14px 0px 14px 14px;
    cursor: pointer;
  }

  .right-space {
    margin: 14px 38px 14px 0px;
  }
`;

const Title = styled.p<Style>`
  margin: 0 auto 0 auto;
  padding-left: ${({ isCampDetail }) => (isCampDetail ? '14px' : '')};
  padding-right: ${({ isEvent, center }) =>
    isEvent ? '30px' : center ? '' : '52px'};
  font-size: ${({ theme }) => theme.fontSizes.h16};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    display: flex;
  }
`;

const BookmarkShareWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg {
    margin: 14px 9px 14px 0px;
    cursor: pointer;
  }
`;

const AndroidEmptyDiv = styled.div`
  width: 33px;
`;
