import { memo, useMemo } from 'react';
import { TextVariant, TextWeight } from 'static/styles/style';
import styled from 'styled-components';

interface Props {
  id: string;
  size?: 'small' | 'large';
  isOn?: boolean;
  onClick: () => void;
  labelVariant: TextVariant;
  labelWeight: TextWeight;
  leftLabel?: string;
  rightLabel?: string;
  clickableAreaLabel?: boolean;
  padding?: string;
  margin?: string;
  align?: 'start' | 'center' | 'end';
}

function LabeledSwitch({
  id,
  size = 'small',
  isOn,
  onClick,
  labelVariant,
  labelWeight,
  leftLabel,
  rightLabel,
  clickableAreaLabel = false,
  padding,
  margin,
  align,
}: Props) {
  const classes = useMemo(() => {
    return `typography-${labelVariant} typography-${labelWeight}`;
  }, [labelVariant, labelWeight]);

  const onClickSwitch = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.preventDefault();
    onClick();
  };

  const getAlign = () => {
    switch (align) {
      case 'start':
        return 'flex-start';
      case 'center':
        return 'center';
      case 'end':
        return 'flex-end';
      default:
        return 'flex-end';
    }
  };

  return (
    <SwitchToggle
      isOn={isOn}
      size={size}
      onClick={onClickSwitch}
      hasRightText={!!(!leftLabel && rightLabel)}
      htmlFor={clickableAreaLabel ? id : undefined}
      padding={padding}
      margin={margin}
      align={getAlign()}
      className={classes}
    >
      {leftLabel && leftLabel}
      <input type="checkbox" id={id} className={isOn ? 'checked' : ''} />
      {rightLabel && rightLabel}
    </SwitchToggle>
  );
}

export default memo(LabeledSwitch);

interface IStyle {
  size: 'small' | 'large';
  isOn?: boolean;
  hasRightText?: boolean;
  padding?: string;
  margin?: string;
  align: 'flex-start' | 'center' | 'flex-end';
}

const SwitchToggle = styled.label<IStyle>`
  position: relative;
  display: flex;
  gap: 6.25px;
  padding: ${({ padding }) => padding || 0};
  margin: ${({ margin }) => margin || ' 0 0 2px 0'};
  justify-content: ${({ align }) => align};

  input {
    width: ${({ size }) => (size === 'small' ? 32 : 52)}px;
    height: ${({ size }) => (size === 'small' ? 18 : 30)}px;
    color: transparent;
    position: relative;
    cursor: pointer;
    -webkit-transition: 0.4s;
    transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
    border-radius: ${({ size }) => (size === 'small' ? '14px' : '34px')};
    background-color: ${({ theme }) => theme.newColors.lightGrey2};

    &.checked {
      background-color: ${({ theme }) => theme.newColors.primary};
      transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
      :before {
        -webkit-transform: ${({ size }) =>
          size === 'small' ? 'translateX(14px)' : 'translateX(22px)'};
        -ms-transform: ${({ size }) =>
          size === 'small' ? 'translateX(14px)' : 'translateX(22px)'};
        transform: ${({ size }) =>
          size === 'small' ? 'translateX(14px)' : 'translateX(22px)'};
      }
    }

    :before {
      position: absolute;
      content: '';
      width: ${({ size }) => (size === 'small' ? 14 : 24)}px;
      height: ${({ size }) => (size === 'small' ? 14 : 24)}px;
      left: ${({ size }) => (size === 'small' ? 2 : 3)}px;
      bottom: ${({ size }) => (size === 'small' ? 2 : 3)}px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s cubic-bezier(0, 1, 0.5, 1);
      border-radius: 50%;
      box-shadow: ${({ size }) =>
        size === 'small'
          ? '-2px 2px 4px rgba(0, 0, 0, 0.15)'
          : '-3px 3px 6px rgba(0, 0, 0, 0.15)'};
    }
  }
`;
