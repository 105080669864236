import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ITShopbyErrorRes,
  IShopbyGetOrderClaimInfoRequest,
  IShopbyGetInfoForClaimResponsePayload,
  IEstimateShopbyClaimPriceRequestPayload,
  IEstimateShopbyClaimPriceResponsePayload,
  ICreateShopbyCancelClaimsRequestPayload,
  IShopbyGetClaimDetailResponsePayload,
  ICreateShopbyReturnClaimsRequestPayload,
  IShopbyCheckClaimWithdrawablePayload,
  IEstimateShopbyClaimPriceForOneRequestPayload,
  ICreateShopbyExchangeClaimsRequestPayload,
} from '@types';
import { AxiosError } from 'axios';
import { SHOPBY_CLAIM_TYPE } from 'constants/shopbyConstants';
import { TShopbyClaimState } from 'store/types';
import { IModalContent } from '../../@types/index';

const initialBaseState = {
  optionInfo: {
    data: undefined,
    shopbyError: undefined,
  },
  priceInfo: {
    data: undefined,
    shopbyError: undefined,
  },
  claim: {
    data: undefined,
    frontError: undefined,
    shopbyError: undefined,
  },
};

const initialDetailBaseState = {
  data: undefined,
  shopbyError: undefined,
};

const initialState: TShopbyClaimState = {
  CANCEL: initialBaseState,
  RETURN: initialBaseState,
  EXCHANGE: initialBaseState,
  detail: initialDetailBaseState,
  withdraw: {
    // 샵바이 클레임 철회 시 유효성 검사 결과 상태
    claimWithdrawableState: initialDetailBaseState,
    result: undefined,
    shopbyError: undefined,
  },
};

export const shopbyClaimSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    getInfoForClaimRequest: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        payload: IShopbyGetOrderClaimInfoRequest;
      }>,
    ) => {},
    getInfoForClaimSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        data: IShopbyGetInfoForClaimResponsePayload;
        requestPayload: IShopbyGetOrderClaimInfoRequest;
      }>,
    ) => {
      const { key, data } = action.payload;
      state[key].optionInfo.data = data;
    },
    getInfoForClaimFailure: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
      }>,
    ) => {
      const { key, error } = action.payload;
      state[key].optionInfo.shopbyError = error;
    },
    estimatePriceForClaimRequest: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        payload: IEstimateShopbyClaimPriceRequestPayload;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].priceInfo.shopbyError = undefined;
    },
    estimatePriceForClaimSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        data: IEstimateShopbyClaimPriceResponsePayload;
        requestPayload: IEstimateShopbyClaimPriceRequestPayload;
      }>,
    ) => {
      const { key, data } = action.payload;
      state[key].priceInfo.data = data;
    },
    estimatePriceForClaimFailure: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
      }>,
    ) => {
      const { key, error } = action.payload;
      state[key].priceInfo.shopbyError = error;
    },
    estimatePriceForOneOptionClaimRequest: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        payload: IEstimateShopbyClaimPriceForOneRequestPayload;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].priceInfo.shopbyError = undefined;
    },
    estimatePriceForOneOptionClaimSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        data: IEstimateShopbyClaimPriceResponsePayload;
        requestPayload: IEstimateShopbyClaimPriceForOneRequestPayload;
      }>,
    ) => {
      const { key, data } = action.payload;
      state[key].priceInfo.data = data;
    },
    estimatePriceForOneOptionClaimFailure: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
      }>,
    ) => {
      const { key, error } = action.payload;
      state[key].priceInfo.shopbyError = error;
    },
    resetClaimWithKey: (
      state,
      action: PayloadAction<keyof typeof SHOPBY_CLAIM_TYPE>,
    ) => {
      state[action.payload] = initialBaseState;
    },
    resetClaimStore: () => {
      return initialState;
    },
    postClaimRequest: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        payload:
          | ICreateShopbyCancelClaimsRequestPayload
          | ICreateShopbyReturnClaimsRequestPayload;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].claim.shopbyError = undefined;
    },
    postClaimSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        data: any;
        requestPayload:
          | ICreateShopbyCancelClaimsRequestPayload
          | ICreateShopbyReturnClaimsRequestPayload;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].claim.data = 'success';
    },
    postClaimFailure: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
      }>,
    ) => {
      const { key, error } = action.payload;
      state[key].claim.shopbyError = error;
    },

    postExchangeClaimRequest: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        payload: ICreateShopbyExchangeClaimsRequestPayload;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].claim.shopbyError = undefined;
    },
    postExchangeClaimSuccess: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        data: any;
        requestPayload: ICreateShopbyExchangeClaimsRequestPayload;
      }>,
    ) => {
      const { key } = action.payload;
      state[key].claim.data = 'success';
    },
    postExchangeClaimFailure: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
      }>,
    ) => {
      const { key, error } = action.payload;
      state[key].claim.shopbyError = error;
    },
    setClaimFrontError: (
      state,
      action: PayloadAction<{
        key: keyof typeof SHOPBY_CLAIM_TYPE;
        modalContents: IModalContent | undefined;
      }>,
    ) => {
      const { key, modalContents } = action.payload;
      state[key].claim.frontError = modalContents;
    },
    getClaimDetailRequestByClaimNo: (
      state,
      action: PayloadAction<string>,
    ) => {},
    getClaimDetailSuccess: (
      state,
      action: PayloadAction<IShopbyGetClaimDetailResponsePayload>,
    ) => {
      state.detail.data = action.payload;
    },
    getClaimDetailFailure: (
      state,
      action: PayloadAction<
        any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>
      >,
    ) => {
      state.detail.shopbyError = action.payload;
    },
    getClaimDetailRequestByOrderOptionNo: (
      state,
      action: PayloadAction<string>,
    ) => {},
    checkIsClaimWithdrawableRequest: (
      state,
      action: PayloadAction<string>,
    ) => {},
    checkIsClaimWithdrawableSuccess: (
      state,
      action: PayloadAction<IShopbyCheckClaimWithdrawablePayload>,
    ) => {
      state.withdraw.claimWithdrawableState.data = action.payload;
    },
    checkIsClaimWithdrawableFailure: (
      state,
      action: PayloadAction<
        any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>
      >,
    ) => {
      state.withdraw.claimWithdrawableState.shopbyError = action.payload;
    },
    withdrawClaimRequest: (state, action: PayloadAction<number>) => {},
    withdrawClaimSuccess: state => {
      state.withdraw.result = 'success';
    },
    withdrawClaimFailure: (
      state,
      action: PayloadAction<
        any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>
      >,
    ) => {
      state.withdraw.result = 'error';
      state.withdraw.shopbyError = action.payload;
    },
    resetCheckIsClaimWithdrawableState: state => {
      state.withdraw.claimWithdrawableState = initialDetailBaseState;
    },
    resetWithdrawClaimState: state => {
      state.withdraw.result = undefined;
      state.withdraw.shopbyError = undefined;
    },
    resetWithdrawWholeState: state => {
      state.withdraw.claimWithdrawableState = initialDetailBaseState;
      state.withdraw.result = undefined;
      state.withdraw.shopbyError = undefined;
    },
  },
});

export const {
  getInfoForClaimRequest,
  getInfoForClaimSuccess,
  getInfoForClaimFailure,
  estimatePriceForClaimRequest,
  estimatePriceForClaimSuccess,
  estimatePriceForClaimFailure,
  estimatePriceForOneOptionClaimRequest,
  estimatePriceForOneOptionClaimSuccess,
  estimatePriceForOneOptionClaimFailure,
  resetClaimWithKey,
  resetClaimStore,
  postClaimRequest,
  postClaimSuccess,
  postClaimFailure,
  postExchangeClaimRequest,
  postExchangeClaimSuccess,
  postExchangeClaimFailure,
  getClaimDetailRequestByClaimNo,
  getClaimDetailRequestByOrderOptionNo,
  getClaimDetailSuccess,
  getClaimDetailFailure,
  checkIsClaimWithdrawableRequest,
  checkIsClaimWithdrawableSuccess,
  checkIsClaimWithdrawableFailure,
  withdrawClaimRequest,
  withdrawClaimSuccess,
  withdrawClaimFailure,
  resetCheckIsClaimWithdrawableState,
  resetWithdrawClaimState,
  resetWithdrawWholeState,
  setClaimFrontError,
} = shopbyClaimSlice.actions;

export default shopbyClaimSlice.reducer;
