import { TOptionDefault } from '@types';

export const SHOPBY_ORDER_REQUEST_TYPE = {
  DEPOSIT_WAIT: '입금대기',
  PAY_DONE: '결제 완료',
  PRODUCT_PREPARE: '상품 준비 중',
  DELIVERY_PREPARE: '배송 준비 중',
  DELIVERY_ING: '배송중',
  DELIVERY_DONE: '배송 완료',
  BUY_CONFIRM: '구매확정',
  CANCEL_DONE: '취소 완료',
  RETURN_DONE: '반품 완료',
  EXCHANGE_DONE: '교환 완료',
  CANCEL_PROCESSING: '취소 처리중',
  RETURN_PROCESSING: '반품 처리 중',
  EXCHANGE_WAIT: '교환 대기 중',
  EXCHANGE_PROCESSING: '교환 처리 중',
  REFUND_DONE: '환불완료',
} as const;

export const SHOPBY_PG_TYPE = {
  DUMMY: '없음',
  PAYCO: '페이코',
  PAYPAL: '페이팔',
  STRIPE: 'STRIPE',
  KCP: 'KCP',
  INICIS: '이니시스',
  NONE: 'PG없음',
  KCP_MOBILE: 'KCP(모바일)',
  KCP_APP: 'KCP(앱)',
  NAVER_PAY: '네이버페이(주문형)',
  LIIVMATE: '리브메이트',
  PAYPALPRO: 'PAYPAL PRO',
  ATHOR_NET: 'AthorizeNet',
  KAKAO_PAY: '카카오페이',
  NAVER_EASY_PAY: '네이버페이(간편결제)',
  LG_U_PLUS: '토스페이먼츠',
  TOSS_PAYMENTS: '토스페이먼츠',
  CHAI: '차이',
  SMARTRO_PAY: '스마트로페이',
  NICEPAY: '나이스페이',
  MY_PAY: '마이페이',
  VERITRANS: '베리트랜스',
} as const;

export const SHOPBY_PAY_TYPE = {
  CASH: '현금',
  CREDIT_CARD: '신용카드',
  ACCOUNT: '무통장입금',
  MOBILE: '휴대폰결제',
  REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체',
  VIRTUAL_ACCOUNT: '가상계좌',
  GIFT: '상품권',
  ATM: 'ATM',
  PAYCO: 'PAYCO',
  ZERO_PAY: '0원결제',
  ACCUMULATION: '적립금 전액 사용',
  PHONE_BILL: '전화결제',
  POINT: '포인트결제',
  YPAY: '옐로페이',
  KPAY: '케이페이',
  PAYPIN: '페이핀',
  INIPAY: 'INIPay 간편결제',
  PAYPAL: 'PAYPAL',
  STRIPE: 'STRIPE',
  NAVER_PAY: '네이버페이 주문형',
  KAKAO_PAY: '카카오페이',
  NAVER_EASY_PAY: '네이버페이 결제형',
  SAMSUNG_PAY: '삼성페이',
  CHAI: '차이',
  TOSS_PAY: '토스페이',
  SK_PAY: 'SK페이',
  APPLE_PAY: '애플페이',
  ESCROW_REALTIME_ACCOUNT_TRANSFER: '실시간계좌이체-에스크로',
  ESCROW_VIRTUAL_ACCOUNT: '가상계좌-에스크로',
  RENTAL: '렌탈결제',
  VERITRANS_CARD: 'Veritrans CreditCard',
  TOASTCAM: '토스트캠',
  ETC: '기타결제수단',
} as const;

export const SHOPBY_CLAIM_TYPE = {
  CANCEL: '취소',
  EXCHANGE: '교환',
  RETURN: '반품',
};

export const SHOPBY_NEXT_ACTION_TYPE = {
  // CANCEL_ALL: '주문취소',
  CANCEL: '취소',
  EXCHANGE: '교환',
  RETURN: '반품',
  WITHDRAW_CANCEL: '취소철회',
  WITHDRAW_EXCHANGE: '교환철회',
  WITHDRAW_RETURN: '반품철회',
  // NOTE: VIEW_CLAIM의 경우 취소,교환,반품 구분해서 어미 붙여서 써야함.
  VIEW_CLAIM: '정보',
  VIEW_DELIVERY: '배송조회',
  CONFIRM_ORDER: '구매확정',
  WRITE_REVIEW: '리뷰 작성',
  // NOTE : 상품 상세 페이지 내 배송지 정보 변경 버튼에만 사용됨.
  CHANGE_ADDRESS: '배송지변경',
} as const;

export const SHOPBY_NOT_RENDERED_NEXT_ACTION_TYPE = {
  CHANGE_ADDRESS: '배송지변경',
  // NOTE: 안 쓸 수 있는 action인지 확인 필요
  DELIVERY_DONE: '수취확인',
  ISSUE_CASH_RECEIPT: '현금영수증 발행',
  VIEW_RECEIPT: '현금영수증 조회',
} as const;

export const SHOPBY_NOT_RENDERED_NEXT_ACTION_TYPE_ARRAY = Object.keys(
  SHOPBY_NOT_RENDERED_NEXT_ACTION_TYPE,
);

export const SHOPBY_SEPERATELY_RENDERED_NEXT_ACTION_TYPE_ARRAY = [
  'CONFIRM_ORDER',
];

export const SHOPBY_NOT_INFO_NEXT_ACTION_TYPE = [
  'CHANGE_ADDRESS',
  'CANCEL_PROCESSING',
  'CANCEL_DONE',
];

export const SHOPBY_REFUND_TYPE = {
  PG: 'PG 환불',
  CANCEL_DEPOSIT: '입금 전 취소',
  ACCOUNT: '무통장입금 환불',
  ACCUMULATION: '적립금 환불',
  ZERO_REFUND: '0원 환불',
  CASH: '현금 환불',
  PAYCO: '페이코 환불',
  PAYPAL: 'PAYPAL 환불',
  STRIPE: 'STRIPE 환불',
  KCP: 'KCP 환불',
  CREDIT_CARD: '신용카드 환불',
  LIIVMATE: '리브메이트 환불',
  INICIS: '이니시스 환불',
  NAVER_EASY_PAY: '네이버페이(간펼결제) 환불',
  KAKAO_PAY: '카카오페이 환불',
  NAVER_PAY: '네이버(주문형) 환불',
  LG_U_PLUS: '엘지유플러스 환불',
  TOSS_PAYMENTS: '토스페이먼츠 환불',
  FORCE_REFUND: '강제 환불',
  DUMMY: '없음',
} as const;

export const SHOPBY_ORDER_STATUS_TYPE = {
  DEPOSIT_WAIT: '입금대기',
  PAY_DONE: '결제완료',
  PRODUCT_PREPARE: '상품 준비 중',
  DELIVERY_PREPARE: '배송 준비 중',
  DELIVERY_ING: '배송중',
  DELIVERY_DONE: '배송 완료',
  BUY_CONFIRM: '구매확정',
  CANCEL_DONE: '취소 완료',
  RETURN_DONE: '반품 완료',
  EXCHANGE_DONE: '교환 완료',
  PAY_WAIT: '결제대기',
  PAY_CANCEL: '결제포기',
  PAY_FAIL: '결제실패',
  DELETE: '삭제',
  EXCHANGE_WAIT: '교환 대기',
  REFUND_DONE: '환불 완료',
} as const;

export const SHOPBY_CLAIM_STATUS_TYPE = {
  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : CANCEL_PROCESSING
  CANCEL_REQUEST: '취소 처리 중', // 승인대기
  CANCEL_NO_REFUND: '취소 완료', // 환불없음
  CANCEL_PROC_REQUEST_REFUND: '취소 처리 중', // 환불보류
  CANCEL_PROC_WAITING_REFUND: '취소 처리 중', // 환불대기

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : CANCEL_DONE
  CANCEL_DONE: '취소 완료', // 환불완료

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : RETURN_PROCESSING
  RETURN_REQUEST: '반품 요청', // 승인대기
  RETURN_REJECT_REQUEST: '반품 요청', // 철회대기
  RETURN_PROC_BEFORE_RECEIVE: '반품 처리 중', // 수거진행
  RETURN_PROC_REQUEST_REFUND: '반품 처리 중', // 환불보류
  RETURN_PROC_WAITING_REFUND: '반품 처리 중', // 환불대기
  RETURN_REFUND_AMT_ADJUST_REQUESTED: '반품 처리 중', // 조정요청

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : RETURN_DONE
  RETURN_DONE: '반품 완료', // 환불완료
  RETURN_NO_REFUND: '반품 완료', // 환불없음

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : EXCHANGE_WAITING
  EXCHANGE_REQUEST: '교환 요청', // 승인대기

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : EXCHANGE_PROCESSING
  EXCHANGE_REJECT_REQUEST: '교환 처리 중', // 철회대기
  EXCHANGE_PROC_BEFORE_RECEIVE: '교환 처리 중', // 수거진행
  EXCHANGE_PROC_REQUEST_PAY: '교환 처리 중', // 결제대기
  EXCHANGE_PROC_REQUEST_REFUND: '교환 처리 중', // 환불보류
  EXCHANGE_PROC_WAITING: '교환 처리 중', // 처리대기
  EXCHANGE_PROC_WAITING_PAY: '교환 처리 중', // 입금처리대기
  EXCHANGE_PROC_WAITING_REFUND: '교환 처리 중', // 환불대기

  // 주문 목록 조회, SHOPBY_ORDER_MENU_MATCHED_REQUEST 기준 : EXCHANGE_DONE
  EXCHANGE_DONE: '교환 완료', // 차액없음
  EXCHANGE_DONE_PAY_DONE: '교환 완료', // 결제완료
  EXCHANGE_DONE_REFUND_DONE: '교환 완료', // 환불완료
} as const;

export const SHOPBY_ORDER_MENU_MATCHED_REQUEST = {
  ALL: ['ALL'],
  ORDER_OR_PAY: ['DEPOSIT_WAIT', 'PAY_DONE'],
  PREPARE_ING: ['PRODUCT_PREPARE', 'DELIVERY_PREPARE'],
  DELIVERY_ING: ['DELIVERY_ING'],
  DELIVERY_DONE: ['DELIVERY_DONE'],
  BUY_CONFIRM: ['BUY_CONFIRM'],
  CANCEL_RETURN_EXCHANGE: [
    'CANCEL_DONE',
    'CANCEL_PROCESSING',
    'RETURN_DONE',
    'RETURN_PROCESSING',
    'EXCHANGE_DONE',
    'EXCHANGE_PROCESSING',
  ],
} as const;

export const SHOPBY_CLAIM_CLASS_TYPE = {
  LEGACY: '레거시',
  ORDER_CANCEL: '전체취소',
  OPTION_CANCEL: '부분취소',
  RETURN_EXCHANGE: '출고 후 교환',
  CANCEL_EXCHANGE: '출고 전 교환',
  RETURN: '반품',
} as const;

export const SHOPBY_CARD_COMPANY_TYPE = {
  ANONYMOUS: '미분류카드',
  BC: 'BC카드',
  KB: 'KB국민카드',
  NH: 'NH농협카드',
  KWANGJU: '광주카드',
  LOTTE_AMEX: '롯데아멕스카드',
  LOTTE: '롯데카드',
  KDB: '산업카드',
  SAMSUNG: '삼성카드',
  SUHYUP: '수협카드',
  SHINSEGAE: '신세계카드',
  SHINHAN: '신한카드',
  SHINHYUP: '신협카드',
  CITY: '씨티카드',
  WOORI: '우리카드',
  UNIONPAY: '은련카드',
  JEOCHOOK: '저축카드',
  JEONBUK: '전북카드',
  JEJU: '제주카드',
  HANA_KEB: '하나(외환)카드',
  HANA: '하나카드',
  HANMI: '한미카드',
  JCB: 'JCB카드',
  MASTER: 'MASTER카드',
  VISA: 'VISA카드',
  HYUNDAI: '현대카드',
  AMEX: 'AMEX카드',
  DINERS: '다이너스카드',
  GWANGJU: '광주카드',
  KAKAO: '카카오뱅크카드',
  KBANK: '케이뱅크카드',
  IBK: '기업은행카드',
  HCB: '국민은행(구주택)',
  NHLOCAL: '단위농협',
  NHLIVESTOCK: '축협중앙회',
  JH: '신한은행(조흥은행)',
  SC: '제일은행',
  DAEGU: '대구은행',
  PUSAN: '부산은행',
  KANGWON: '강원은행',
  GYEONGNAM: '경남은행',
  HSB: '홍콩상하이은행',
  EPOST: '우체국',
  HANA_SEOUL: '하나은행(서울은행)',
  PB: '평화은행',
  SHINHAN_JH: '신한은행(조흥 통합)',
  PAYCO: 'PAYCO',
  KFCC: '새마을금고',
  DISCOVER: 'DISCOVER카드',
  HYUNDAI_STOCK: '현대증권카드',
  NAVER_POINT: '네이버포인트',
  TOSS_MONEY: '토스머니',
  SSG_MONEY: 'SSG머니',
  L_POINT: '엘포인트',
  KAKAO_MONEY: '카카오머니',
} as const;

export const SHOPBY_BANK = {
  ANONYMOUS: '미확인은행',
  KDB: '산업은행',
  IBK: '기업은행',
  KB: '국민은행',
  KEB: '외환은행',
  SUHYUP: '수협',
  KEXIM: '수출입은행',
  NH: 'NH농협은행',
  NHLOCAL: '지역농축협',
  WOORI: '우리은행',
  SC: 'SC제일은행',
  CITY: '한국씨티은행',
  DAEGU: '대구은행',
  PUSAN: '부산은행',
  GWANGJU: '광주은행',
  JEJU: '제주은행',
  JEONBUK: '전북은행',
  GYEONGNAM: '경남은행',
  KFCC: '새마을금고',
  CU: '신협',
  SANGHO: '상호저축은행',
  HSBC: 'HSBC은행',
  DEUTSCHE: '도이치은행',
  ABN: 'ABN',
  JP_MORGAN: 'JP모건',
  MIZUHO: '미즈호',
  UFJ: '엠유에프제이',
  BOA: '뱅크오브아메리카',
  NFCF: '산림조합중앙회',
  EPOST: '우체국',
  KEBHANA: 'KEB하나은행',
  SHINHAN: '신한은행',
  KBANK: '케이뱅크',
  KAKAO: '카카오뱅크',
  TOSS: '토스뱅크',
  YUANTA: '유안타증권',
  KBSEC: 'KB증권',
  MIRAE: '미래에셋증권',
  MIRAEDAEWOO: '미래에셋대우증권',
  SAMSUNG: '삼성증권',
  HANKOOK: '한국투자증권',
  NH_INVEST: 'NH투자증권',
  KYOBO: '교보증권',
  HI_INVEST: '하이투자증권',
  HMC_INVEST: 'HMC투자증권',
  KIWOOM: '키움증권',
  EBEST: '이베스트투자증권',
  SK: 'SK증권',
  DAISHIN: '대신증권',
  SOLOMON_INVEST: '솔로몬증권',
  HANHWA: '한화투자증권',
  HANA_INVEST: '하나금융투자',
  SHINHAN_INVEST: '신한금융투자',
  DONGBU: 'DB금융투자',
  EUGENE_INVEST: '유진투자증권',
  MERITZ_COMPREHENSIVE: '메리츠종합금융증권',
  BOOKOOK: '부국증권',
  SHINYOUNG: '신영증권',
  CAPE: '케이프투자증권',
  SMBC: '미쓰이스미토모은행',
  RESONA: '리소나은행',
  NAVER_POINT: '네이버포인트',
  TOSS_MONEY: '토스머니',
  SSG_MONEY: '신세계포인트',
  L_POINT: '엘포인트',
  KAKAO_MONEY: '카카오머니',
  PAYCO: '페이코포인트',
  BW: '뱅크월렛',
} as const;

export const SHOPBY_AGREEMENT = {
  TERMS_OF_USE: '이용약관',
  PRIVACY_USAGE_AGREEMENT: '개인정보 이용동의',
  NONE_MEMBER_PRIVACY_USAGE_AGREEMENT: '비회원 개인정보 수집 · 이용 동의',
  SELLER_PRIVACY_USAGE_AGREEMENT: '개인정보 판매자 제공 동의',
  CUSTOMS_CLEARANCE_AGREEMENT: '통관정보 수집 · 이용 동의',
  OVERSEA_PRIVACY_USAGE_AGREEMENT: '개인정보 국외 이전 동의',
  ORDER_INFO_AGREE: '주문 상품 정보 동의',
  PI_LIQUOR_PURCHASE_PROVISION: '주류구매 개인정보 제공 동의',
  REGULAR_PAYMENT_USE: '정기결제(배송) 이용약관',
  AUTO_APPROVAL_USE: '자동 승인 이용약관',
} as const;

export const SHOPBY_CLAIM_REASON_TYPE = {
  CHANGE_MIND: '단순변심',
  DEFECTIVE_PRODUCT: '상품불량/파손',
  WRONG_DELIVERY: '배송누락/오배송',
  OUT_OF_STOCK_SYSTEM: '재고부족',
  CANCEL_BEFORE_PAY: '입금전취소',
  WRONG_PRODUCT_DETAIL: '상품상세 정보와 다름',
  DELAY_DELIVERY: '판매자 배송 지연',
  OUT_OF_STOCK: '상품 품절/재고 없음',
  OTHERS_SELLER: '기타',
  OTHERS_BUYER: '기타',
  LATER_INPUT_ORDER: '배송미입력 취소',
} as const;

export const SHOPBY_DELIVERY_MEMO_LIST = [
  { label: '문 앞에 놓아주세요', value: '문 앞에 놓아주세요' },
  { label: '택배함에 놓아주세요', value: '택배함에 놓아주세요' },
  { label: '경비실에 맡겨주세요', value: '경비실에 맡겨주세요' },
  { label: '직접입력', value: '직접입력' },
] as TOptionDefault[];

export const SHOPBY_DELIVERY_MEMO_LIST2 = [
  { label: '문 앞에 놓아주세요', value: '문 앞에 놓아주세요' },
  { label: '택배함에 놓아주세요', value: '택배함에 놓아주세요' },
  { label: '경비실에 맡겨주세요', value: '경비실에 맡겨주세요' },
  { label: '직접입력', value: '' },
] as TOptionDefault[];

export const SHOPBY_REASON_OPTION_LIST: {
  [key in keyof typeof SHOPBY_CLAIM_TYPE]: TOptionDefault[];
} = {
  CANCEL: [
    { label: '단순변심', value: 'CHANGE_MIND' },
    { label: '판매자 배송 지연', value: 'DELAY_DELIVERY' },
    { label: '기타', value: 'OTHERS_BUYER' },
  ],
  RETURN: [
    // CASE1: 판매자 귀책 사유 선택지 - 사진 업로드 필수
    { label: '상품불량/파손', value: 'DEFECTIVE_PRODUCT' },
    { label: '누락/오배송', value: 'WRONG_DELIVERY' },
    { label: '상품상세 정보와 다름', value: 'WRONG_PRODUCT_DETAIL' },
    // CASE2: 구매자 귀책 사유 선택지
    { label: '단순변심', value: 'CHANGE_MIND' },
  ],
  EXCHANGE: [
    // CASE1: 판매자 귀책 사유 선택지 - 사진 업로드 필수
    { label: '상품불량/파손', value: 'DEFECTIVE_PRODUCT' },
    { label: '누락/오배송', value: 'WRONG_DELIVERY' },
    { label: '상품상세 정보와 다름', value: 'WRONG_PRODUCT_DETAIL' },
    // CASE2: 구매자 귀책 사유 선택지
    { label: '단순변심', value: 'CHANGE_MIND' },
  ],
};

export const SHOPBY_CLAIM_REASON_FROM_SELLER: (keyof typeof SHOPBY_CLAIM_REASON_TYPE)[] =
  [
    'DELAY_DELIVERY',
    'DEFECTIVE_PRODUCT',
    'WRONG_DELIVERY',
    'WRONG_PRODUCT_DETAIL',
    'OUT_OF_STOCK_SYSTEM',
    'OTHERS_SELLER',
    'OUT_OF_STOCK',
  ];

export const SHOPBY_CLAIM_REASON_FROM_BUYER: (keyof typeof SHOPBY_CLAIM_REASON_TYPE)[] =
  ['CHANGE_MIND', 'OTHERS_BUYER', 'LATER_INPUT_ORDER'];

export const SHOPBY_ACCEPTABLE_IMAGE_EXTENSIONS =
  'image/bmp, image/tif, image/tiff, image/miff, image/gif, image/jpe, image/jpeg, image/jps, image/pjpeg, image/jng, image/mng, image/png' as const;

export const SHOPBY_LAUNCHIG_DATE = '2023-05-01';

export const SHOPBY_CANCEL_REASON_OPTION_LIST = [
  { label: '단순변심', value: 'CHANGE_MIND' },
  { label: '판매자 배송 지연', value: 'DELAY_DELIVERY' },
  { label: '기타(구매자 귀책)', value: 'OTHERS_BUYER' },
] as TOptionDefault[];

export const SHOPBY_ITEM_INQUIRY_TYPE_LIST = [
  { label: '상품', value: 'PRODUCT' },
  { label: '배송', value: 'DELIVERY' },
  { label: '취소', value: 'CANCEL' },
  { label: '반품', value: 'RETURN' },
  { label: '교환', value: 'EXCHANGE' },
  { label: '환불', value: 'REFUND' },
  { label: '기타', value: 'OTHER' },
] as TOptionDefault[];

export const SHOPBY_ACCUMULATION_CONFIG = {
  IMMEDIATE: '즉시적립',
  NEXTDAY: '익일',
  DAY_AFTER_TOMMOROW: '2일',
  AFTER_A_WEEK: '7일',
  AFTER_TWO_WEEK: '14일',
  AFTER_TWENTY_DAYS: '20일',
  AFTER_THIRTY_DAYS: '30일',
  NEXT_MONTH: '익월',
};

export const SHOPBY_PAGE_PATHNAME_KEYWORD = [
  'store',
  'product',
  'products',
  'mypage',
];

export const SHOPBY_WITHDRAWABLE_STATE_FROM_VALIDATION_TYPE = {
  EXISTS_AFTER_CLAIM_WITH_CART_COUPON: false,
  EXISTS_SAME_SHIPPING_NO_CLAIM: false,
  WITHDRAWABLE: true,
};

export const SHOPBY_ERROR_MESSAGE_FROM_VALIDATION_TYPE = {
  EXISTS_AFTER_CLAIM_WITH_CART_COUPON:
    '같은 주문번호 내에 클레임 신청건과 장바구니 쿠폰이 적용되어 있어 철회가 불가합니다',
  EXISTS_SAME_SHIPPING_NO_CLAIM:
    '묶음 배송상품 중 다른 클레임 신청건이 있어 철회가 불가합니다.',
  WITHDRAWABLE: undefined,
};
