import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IItemDetail,
  ITShopbyErrorRes,
  IShopbyItemOptionPayload,
  IAnotherPartnerItemInfo,
  IShopByUserAddress,
  IShopbySaveImagePayload,
  IShopbyClaimAddressBase,
  IGetShopbyMallInfoResponsePayload,
} from '@types';
import { AxiosError } from 'axios';
import { IStoreState } from 'store/types';

const initialState: IStoreState = {
  item: {} as IItemDetail,
  shopbyDeliveryAddress: {} as IShopByUserAddress,
  shopbyDeliveryMemo: '',
  anotherPartnerItemList: [],
  option: undefined,
  activeTab: 0,
  error: '',
  shopbyError: undefined,
  images: {
    data: [],
    success: undefined,
    errorMessage: undefined,
  },
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },

    getItemDetailRequest: (state, action: PayloadAction<number>) => {
      state.item = {} as IItemDetail;
      state.error = '';
    },
    getItemDetailSuccess: (state, action) => {
      state.item = action.payload;
      state.error = '';
    },
    getItemDetailFailure: (state, action: PayloadAction<Error | string>) => {
      state.item = {} as IItemDetail;
      state.error = action.payload;
    },

    setShopbyDeliveryAddress: (
      state,
      action: PayloadAction<IShopByUserAddress | IShopbyClaimAddressBase>,
    ) => {
      state.shopbyDeliveryAddress = action.payload;
    },

    resetShopbyDeliveryAddress: state => {
      state.shopbyDeliveryAddress = {} as IShopByUserAddress;
    },

    getAnotherPartnerListRequest: (state, action: PayloadAction<string>) => {
      state.anotherPartnerItemList = [];
      state.error = '';
    },
    getAnotherPartnerListSuccess: (
      state,
      action: PayloadAction<IAnotherPartnerItemInfo[]>,
    ) => {
      state.anotherPartnerItemList = state.anotherPartnerItemList.concat(
        action.payload,
      );
    },
    getAnotherPartnerListFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.anotherPartnerItemList = [];
      state.error = action.payload;
    },
    getItemOptionRequest: (state, action: PayloadAction<number>) => {
      state.option = undefined;
      state.error = '';
    },
    getItemOptionSuccess: (
      state,
      action: PayloadAction<IShopbyItemOptionPayload>,
    ) => {
      state.option = action.payload;
    },
    getItemOptionError: (state, action: PayloadAction<ITShopbyErrorRes>) => {
      state.shopbyError = action.payload;
    },

    setShopbyDeliveryMemo: (state, action: PayloadAction<string>) => {
      state.shopbyDeliveryMemo = action.payload;
    },
    saveShopbyImagesRequest: (state, action: PayloadAction<FormData[]>) => {},
    saveShopbyImagesSuccess: (
      state,
      action: PayloadAction<IShopbySaveImagePayload[]>,
    ) => {
      state.images.data = action.payload.map(image => image.imageUrl);
    },
    saveShopbyImagesError: (
      state,
      action: PayloadAction<Error | AxiosError>,
    ) => {
      state.images.errorMessage =
        '이미지 업로드 도중 오류가 발생하였습니다. 다시 시도해주세요.';
    },
    resetShopbyImages: state => {
      state.images = {
        data: [],
        success: undefined,
        errorMessage: undefined,
      };
    },
    getShopbyMallInfoRequest: state => {},
    getShopbyMallInfoSuccess: (
      state,
      action: PayloadAction<
        Omit<IGetShopbyMallInfoResponsePayload, 'settingTimestamp'>
      >,
    ) => {},
    getShopbyMallInfoError: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {},
  },
});

export const {
  setActiveTab,

  getItemDetailRequest,
  getItemDetailSuccess,
  getItemDetailFailure,

  setShopbyDeliveryAddress,
  resetShopbyDeliveryAddress,

  getAnotherPartnerListRequest,
  getAnotherPartnerListSuccess,
  getAnotherPartnerListFailure,
  getItemOptionRequest,
  getItemOptionSuccess,
  getItemOptionError,
  setShopbyDeliveryMemo,
  saveShopbyImagesRequest,
  saveShopbyImagesSuccess,
  saveShopbyImagesError,
  resetShopbyImages,
  getShopbyMallInfoRequest,
  getShopbyMallInfoSuccess,
  getShopbyMallInfoError,
} = storeSlice.actions;

export default storeSlice.reducer;
