import { AxiosResponse } from 'axios';
import { all, takeEvery } from 'redux-saga/effects';
import {
  getShopbyItemReviewListRequset,
  getShopbyItemReviewListSuccess,
  getShopbyItemReviewListFailure,
} from 'store/reducers/shopbyReview';
import { ITShopbyErrorRes } from '@types';
import { getShopbyItemReviewList } from 'api/shopby';
import { createShopbyFetchAction } from './createFetchAction';

function* getShopbItemyReviewListSga() {
  yield takeEvery(
    getShopbyItemReviewListRequset.type,
    createShopbyFetchAction(
      getShopbyItemReviewList,
      getShopbyItemReviewListSuccess,
      getShopbyItemReviewListFailure,
      undefined,
      undefined,
      function onError(errorPayload: ITShopbyErrorRes) {
        alert(errorPayload.message);
      },
    ),
  );
}

export default function* shopbyItemReviewSaga() {
  yield all([getShopbItemyReviewListSga()]);
}
