import { Route, Redirect } from 'react-router-dom';

const TestRoute = (props: any) => {
  const { component: Component, location, ...rest } = props;
  const nickname = localStorage.getItem('nickname');

  return (
    <Route
      {...rest}
      render={routeProps => {
        return nickname?.includes('test') ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={{ pathname: '/' }} />
        );
      }}
    />
  );
};

export default TestRoute;
