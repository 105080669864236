import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  ITShopbyErrorRes,
  IShopbyRevewListResponse,
  IShopbyRevewListRequest,
} from '@types';
import { AxiosError, AxiosResponse } from 'axios';
import { IShopbyReviewState } from 'store/types';

const initialState: IShopbyReviewState = {
  totalCount: 0,
  rate: 0,
  reviewRatingResponses: [],
  items: [],
  page: 1,
  pageNumber: 10,
  shopbyError: undefined,
  reviewFilterState: {
    pageNumber: 1,
    pageSize: 10,
    hasTotalCount: true,
    orderDirertion: 'DESC',
    orderBy: 'RECOMMEND',
    bestReviewYn: 'N',
    hasAttachmentFile: true,
  },
};

export const shopbyReviewListSlice = createSlice({
  name: 'shopbyReviewList',
  initialState,
  reducers: {
    getShopbyItemReviewListRequset: (
      state,
      action: PayloadAction<IShopbyRevewListRequest>,
    ) => {
      state.totalCount = 0;
      state.rate = 0;
      state.reviewRatingResponses = [];
      state.items = [];
      state.shopbyError = undefined;
    },
    getShopbyItemReviewListSuccess: (
      state,
      action: PayloadAction<IShopbyRevewListResponse>,
    ) => {
      const { totalCount, rate, reviewRatingResponses, items } = action.payload;

      state.totalCount = totalCount;
      state.rate = rate;
      state.reviewRatingResponses = reviewRatingResponses;
      state.items = state.items.concat(items);
      state.shopbyError = undefined;
    },
    getShopbyItemReviewListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },
    // getMoreShopbyReviewList: (state, action) => {}, // TODO
  },
});

export const {
  getShopbyItemReviewListRequset,
  getShopbyItemReviewListSuccess,
  getShopbyItemReviewListFailure,
  //   getMoreShopbyReviewList,// TODO
} = shopbyReviewListSlice.actions;

export default shopbyReviewListSlice.reducer;
