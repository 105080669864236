import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import { PayloadAction, PayloadActionCreator } from '@reduxjs/toolkit';
import { startLoading, finishLoading } from 'store/reducers/loading';
import { IResponse, ITShopbyErrorRes } from '@types';
import { IErrorPayloadWithKey } from 'store/types';

export function* fetchApi<P, T>(
  api: any,
  requestActionType: string,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<AxiosError>,
  payload: P,
) {
  yield put(startLoading(requestActionType));
  try {
    const data: Promise<any> = yield call(api, payload);
    yield put(successAction(data));
  } catch (e: any) {
    if (e.response.status === 429) {
      window.location.replace('/error');
    }
    yield put(failureAction(e));
  }
  yield put(finishLoading(requestActionType));
}

export function createShopbyFetchAction<P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  errorAction: PayloadActionCreator<ITShopbyErrorRes>,
  params?: any,
  successFunc?: any,
  errorFunc?: any,
) {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;
    yield put(startLoading(type));

    try {
      const data: Promise<T> = yield call(
        api,
        params ? { ...params, ...payload } : payload,
      );

      yield put(successAction({ ...data, requestPayload: payload }));
      if (successFunc) {
        yield call<typeof successFunc>(successFunc, data, payload);
      }
    } catch (e: any | AxiosError<ITShopbyErrorRes>) {
      if (e.response?.status === 401) {
        yield localStorage.removeItem('shopbyAccessToken');
        yield alert('토큰이 만료되었습니다. 다시 시도해주세요.');
        yield window.location.reload();
      }
      yield put(errorAction(e.response?.data || e));
      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e.response?.data || e);
      }
    }
    yield put(finishLoading(type));
  };
}
export function createShopbyFetchActionWithKey<P, T>(
  api: any,
  successAction: PayloadActionCreator<{
    key: any;
    data: T;
    requestPayload: P;
  }>,
  errorAction: PayloadActionCreator<{
    key: any;
    error: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>;
  }>,
  params?: any,
  successFunc?: any,
  errorFunc?: any,
) {
  return function* fetchApi(action: PayloadAction<{ key: any; payload: any }>) {
    const { type, payload } = action;
    const { key, payload: requestPayload } = payload;
    yield put(startLoading(type));

    try {
      const data: T = yield call(
        api,
        params ? { ...params, ...requestPayload } : requestPayload,
      );

      yield put(successAction({ key, data, requestPayload }));
      if (successFunc) {
        yield call<typeof successFunc>(successFunc, data, requestPayload, key);
      }
    } catch (e: any | ITShopbyErrorRes | AxiosError<ITShopbyErrorRes>) {
      console.log(e.response);
      if (e.response.status === 401) {
        yield localStorage.removeItem('shopbyAccessToken');
        yield alert('토큰이 만료되었습니다. 새로고침 후 다시 시도해주세요.');
      }

      yield put(errorAction({ key, error: e.response?.data || e }));
      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e.response?.data || e);
      }
    }

    yield put(finishLoading(type));
  };
}

export const createV2FetchAction = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<AxiosError>,
  params?: any,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;

    yield put(startLoading(type));

    try {
      const data: Promise<any> = yield call(
        api,
        params ? { ...params, ...payload } : payload,
      );
      yield put(successAction(data));

      if (successFunc) {
        yield call<typeof successFunc>(successFunc, data, payload);
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }
      yield put(failureAction(e));
      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

export const createV2FetchFuncWithStringError = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<Error | string>,
  params?: any,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(
        api,
        params ? { ...params, ...payload } : payload,
      );
      const { status, message } = data;

      if (status && status === 'fail') {
        if (message) {
          yield put(failureAction(message));
        }

        if (failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (successFunc) {
          yield call<typeof successFunc>(successFunc, data, payload);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      yield put(failureAction(e));

      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

export const createFetchAction = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<Error | string>,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<P>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status && status === 'fail') {
        if (message) {
          yield put(failureAction(message));
        }

        if (failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (successFunc) {
          yield call<typeof successFunc>(successFunc, data);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      yield put(failureAction(e));

      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

export const createFetchActionWithFailResult = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<T>,
  errorAction?: PayloadActionCreator<Error | string>,
  successFunc?: any,
  failureFunc?: any,
  errorFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<any>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status && status === 'fail') {
        yield put(failureAction(data));

        if (payload.failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (payload.successFunc) {
          yield call<typeof successFunc>(successFunc, data);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      if (errorAction) {
        yield put(errorAction(e));
      }

      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

export const createActionWithFailResult = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<T>,
  errorAction?: PayloadActionCreator<Error | string>,
  successFunc?: any,
  failureFunc?: any,
  errorFunc?: any,
) => {
  return function* fetchApi(action: PayloadAction<any>) {
    const { type, payload } = action;

    yield put(startLoading(type));
    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status && status === 'fail') {
        yield put(failureAction(data));

        if (payload.failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction(data));

        if (payload.successFunc) {
          yield call<typeof successFunc>(successFunc, data);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      if (errorAction) {
        yield put(errorAction(e));
      }

      if (errorFunc) {
        yield call<typeof errorFunc>(errorFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};

/** NOTE:
 * fail, error 등의 상태 값을 따로 리덕스 스토어 내에서 전역적으로 관리할 필요 없을 때 사용
 * 예) 서버 결과값에 따라 모달창으로 안내 메세지만 표출하는 로직일 경우 */

export const createSimpleAction = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction?: PayloadActionCreator<T>,
  errorAction?: PayloadActionCreator<Error | AxiosError | any>,
) => {
  return function* fetchApi(action: PayloadAction<any>) {
    const { type, payload } = action;

    yield put(startLoading(type));

    try {
      const data: T & IResponse = yield call(api, payload);
      const { status, message } = data;

      if (status === 'fail') {
        if (failureAction) {
          yield put(failureAction(data));
        }

        if (payload.functions.onFail) {
          yield call<typeof payload.functions.onFail>(
            payload.functions.onFail,
            message,
          );
        }
      } else {
        yield put(successAction(data));

        if (payload.functions.onSuccess) {
          yield call<typeof payload.functions.onSuccess>(
            payload.functions.onSuccess,
            data,
          );
        }
      }
    } catch (e: any) {
      if (e.response?.status === 429) {
        window.location.replace('/error');
      }

      if (errorAction) {
        yield put(errorAction(e));
      }

      if (payload.functions.onError) {
        yield call<typeof payload.functions.onError>(
          payload.functions.onError,
          e,
        );
      }
    }
    yield put(finishLoading(type));
  };
};

// export const createActionWithBootpay = <P, T>(
//   api: any,
//   successAction: PayloadActionCreator<T>,
//   failureAction: PayloadActionCreator<ISelfAuthenticationError>,
//   successFunc?: any,
//   failureFunc?: any,
// ) => {
//   return function* fetchApi(action: PayloadAction<any>) {
//     const { type, payload } = action;
//     const { params } = payload;

//     yield put(startLoading(type));
//     try {
//       const response: ISelfAuthenticationPayload = yield call(api, params);

//       switch (response.event) {
//         case 'done':
//           // 완료, 성공시 done 이벤트만 받는다 (만약 분리승인 옵션을 줬을 경우 confirm 도 수신하게 됨)
//           yield put(successAction(response.data));
//           if (payload.successFunc) {
//             yield call<typeof successFunc>(successFunc, response.data);
//           }
//           break;
//         default:
//           console.log(response.data);
//       }
//     } catch (e: any) {
//       if (failureFunc) {
//         yield put(failureAction(e));
//       }

//       if (failureFunc) {
//         yield call<typeof failureFunc>(failureFunc, e);
//       }
//     }
//     yield put(finishLoading(type));
//   };
// };

export const createFetchActionWithKey = <P, T>(
  api: any,
  successAction: PayloadActionCreator<T>,
  failureAction: PayloadActionCreator<IErrorPayloadWithKey>,
  params?: any,
  successFunc?: any,
  failureFunc?: any,
) => {
  return function* fetchApi(
    action: PayloadAction<{ key: string; [param: string]: any }>,
  ) {
    const { type, payload } = action;

    yield put(startLoading(type));

    try {
      const data: T & IResponse = yield call(
        api,
        params ? { ...params, ...payload } : payload,
      );
      const { status, message } = data;

      if (status && status === 'fail') {
        yield put(failureAction({ key: payload.key, error: message }));

        if (payload.failureFunc) {
          yield call<typeof failureFunc>(failureFunc, data);
        }
      } else {
        yield put(successAction({ key: payload.key, data }));

        if (payload.successFunc) {
          yield call<typeof payload.successFunc>(
            payload.successFunc,
            data,
            payload,
          );
        }

        if (successFunc) {
          yield call<typeof successFunc>(successFunc, data, payload);
        }
      }
    } catch (e: any) {
      if (e.response.status === 429) {
        window.location.replace('/error');
      }

      yield put(failureAction({ key: payload.key, error: e }));

      if (failureFunc) {
        yield call<typeof failureFunc>(failureFunc, e);
      }
    }
    yield put(finishLoading(type));
  };
};
