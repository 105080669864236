import { customHistory } from 'App';
import { AxiosError, AxiosResponse } from 'axios';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getAnotherPartnerList, getShopbyItemDetail } from 'api';
import {
  getItemDetailFailure,
  getItemDetailRequest,
  getItemDetailSuccess,
  getAnotherPartnerListFailure,
  getAnotherPartnerListRequest,
  getAnotherPartnerListSuccess,
  getItemOptionRequest,
  getItemOptionSuccess,
  getItemOptionError,
  saveShopbyImagesRequest,
  saveShopbyImagesSuccess,
  saveShopbyImagesError,
  getShopbyMallInfoRequest,
  getShopbyMallInfoSuccess,
  getShopbyMallInfoError,
} from 'store/reducers/store';
import {
  IGetShopbyMallInfoResponsePayload,
  IShopbySaveImagePayload,
  ITShopbyErrorRes,
} from '@types';
import { PayloadAction } from '@reduxjs/toolkit';
import { startLoading, finishLoading } from 'store/reducers/loading';
import {
  getItemOption,
  getMalls,
  uploadShopbyImageFiles,
} from '../../api/shopby';
import {
  createFetchAction,
  createShopbyFetchAction,
} from './createFetchAction';

function* getAnotherPartnerListSaga() {
  yield takeEvery(
    getAnotherPartnerListRequest.type,
    createFetchAction(
      getAnotherPartnerList,
      getAnotherPartnerListSuccess,
      getAnotherPartnerListFailure,
    ),
  );
}

function* getItemDetilaSaga() {
  yield takeEvery(
    getItemDetailRequest.type,
    createFetchAction(
      getShopbyItemDetail,
      getItemDetailSuccess,
      getItemDetailFailure,
    ),
  );
}

function* getItemOptionSaga() {
  yield takeEvery(
    getItemOptionRequest.type,
    createShopbyFetchAction(
      getItemOption,
      getItemOptionSuccess,
      getItemOptionError,
      undefined,
      undefined,
      function onError(errorRes: AxiosResponse<ITShopbyErrorRes>) {
        alert(errorRes.data.message);
      },
    ),
  );
}

export function* saveShopbyImagesSaga() {
  yield takeLatest(
    saveShopbyImagesRequest.type,
    function* uploadImage(action: PayloadAction<FormData[]>) {
      const { type, payload } = action;
      yield put(startLoading(type));

      try {
        const data: IShopbySaveImagePayload[] = yield call(
          uploadShopbyImageFiles,
          payload,
        );

        yield put(saveShopbyImagesSuccess(data));
      } catch (e: any | AxiosError) {
        yield put(saveShopbyImagesError(e.response?.data || e));
      }

      yield put(finishLoading(type));
    },
  );
}

function* getShopbyMallInfoSaga() {
  yield takeEvery(
    getShopbyMallInfoRequest.type,
    createShopbyFetchAction(
      getMalls,
      getShopbyMallInfoSuccess,
      getShopbyMallInfoError,
      undefined,
      function onSuccess(
        data: Omit<IGetShopbyMallInfoResponsePayload, 'settingTimestamp'>,
      ) {
        const newData = {
          settingTimestamp: new Date().getTime(),
          ...data,
        } as IGetShopbyMallInfoResponsePayload;
        window.localStorage.setItem(
          'camfit_mall_info',
          JSON.stringify(newData),
        );
      },
      function* onError(errorRes: AxiosResponse<ITShopbyErrorRes>) {
        yield alert(
          `데이터를 불러오는데 오류가 발생했습니다. 해당 페이지에 다시 진입해주세요. ${errorRes.data.message}`,
        );
        yield customHistory.goBack();
      },
    ),
  );
}

export default function* storeSaga() {
  yield all([
    getAnotherPartnerListSaga(),
    getItemDetilaSaga(),
    getItemOptionSaga(),
    saveShopbyImagesSaga(),
    getShopbyMallInfoSaga(),
  ]);
}
