import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { getShopbyMallInfoRequest } from 'store/reducers/store';
import ChannelTalkService from 'utils/ChannelTalkService';
import sendGtmDataLayer from 'utils/sendGtmDataLayer';
import { SHOPBY_PAGE_PATHNAME_KEYWORD } from 'constants/shopbyConstants';

const usePageViews = () => {
  const location = useLocation();
  const isLoggedIn = localStorage.getItem('jwt');
  const history = useHistory();
  const dispatch = useDispatch();

  const { trackingId } = useSelector(
    (state: RootState) => state.algoliaSearchReducer,
  );

  useEffect(() => {
    if (ChannelTalkService.isChatOpen) ChannelTalkService.hideMessenger();

    if (!isLoggedIn) {
      sendGtmDataLayer({
        event: 'pageview',
        user_id: undefined,
        crm_id: undefined,
      });
    }
  }, [location]);

  useEffect(() => {
    if (trackingId && isLoggedIn) {
      sendGtmDataLayer({
        event: 'pageview',
        user_id: trackingId,
        crm_id: trackingId,
      });
    }
  }, [location, trackingId]);

  useEffect(() => {
    let isMallInfoRefreshCheckNeeded = false;
    const pathname = history.location?.pathname || '';

    // STEP1. 샵바이를 사용하는 페이지 확인하여 예외처리
    for (const pathKeyword of SHOPBY_PAGE_PATHNAME_KEYWORD) {
      if (pathname.includes(pathKeyword)) isMallInfoRefreshCheckNeeded = true;
    }
    if (!isMallInfoRefreshCheckNeeded) return;

    const mallInfo = window.localStorage.getItem('camfit_mall_info');
    // STEP2. 이미 저장된 샵바이 정보가 있다면, 리프레시가 필요한 상태인지 확인 (0시 기준 갱신, settingTimestamp를 통해 정보를 받아온 날짜가 지났는지 확인)
    if (mallInfo) {
      const settingTime = Number(JSON.parse(mallInfo).settingTimestamp);

      const afterADayFromSettingTime = new Date(
        new Date(settingTime).setDate(new Date(settingTime).getDate() + 1),
      ).setHours(0, 0, 0, 0);
      // NOTE: 현재 일시가 세팅일 기준으로 다음날 0시가 안지났을시 예외처리
      if (afterADayFromSettingTime > new Date().getTime()) {
        return;
      }
    }
    // NOTE: 저장된 mallInfo가 없거나, mallInfo가 있으나 갱신이 필요한 경우 mall 정보 받아오기.
    dispatch(getShopbyMallInfoRequest());
  }, [history]);
};

export default usePageViews;
