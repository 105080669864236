import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ITShopbyErrorRes,
  IShopbyPostOrderSheet,
  IShopbyPostOrderSheetPayload,
  IShopbyGetOrderListRequest,
  IShopbyGetOrdersPayload,
  IShopbyGetOrderDetailPayload,
  IShopbyGetOrderDetailRequest,
  TShopbyGetOrderListMoreRequest,
  IShopbyGetOrderSheetInfoPayload,
  IShopbyGetOrderSheetCalculatedInfoPayload,
  IShopbyGetOrderSheetCalculatedInfoRequestParams,
  IShopbyOrderOptionCountPayload,
  IShopbyUpdateOrderAddressRequest,
} from '@types';
import { AxiosError } from 'axios';
import { IShopbyOrderState, IShopbyOrderStoreListKey } from 'store/types';

const initialBaseState = {
  data: undefined,
  shopbyError: undefined,
};

const initialResultBaseState = {
  result: undefined,
  shopbyError: undefined,
};

const initialListState = {
  ...initialBaseState,
  key: '',
  totalCount: 0,
  paging: {
    pageNumber: 1,
    pageSize: 10,
  },
};

const initialState: IShopbyOrderState = {
  orderSheet: {
    data: {
      orderSheetNo: undefined,
      orderInfo: undefined,
      calculatedInfo: undefined,
    },
    shopbyError: undefined,
  },
  orderCountSummary: initialBaseState,
  activeTabKey: 'ALL',
  list: initialListState,
  detail: initialBaseState,
  updateAddress: initialResultBaseState,
  confirmPurchase: initialResultBaseState,
};

export const shopbyOrderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    postShopbyOrderSheetRequest: (
      state,
      action: PayloadAction<IShopbyPostOrderSheet>,
    ) => {},
    postShopbyOrderSheetSuccess: (
      state,
      action: PayloadAction<IShopbyPostOrderSheetPayload>,
    ) => {
      state.orderSheet.data.orderSheetNo = action.payload.orderSheetNo;
    },
    postShopbyOrderSheetError: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.orderSheet.shopbyError = action.payload;
    },

    getOrderSheetInfoRequest: (state, action: PayloadAction<string>) => {},
    getOrderSheetInfoSuccess: (
      state,
      action: PayloadAction<IShopbyGetOrderSheetInfoPayload>,
    ) => {
      state.orderSheet.data.orderInfo = action.payload;
    },
    getOrderSheetInfoFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.orderSheet.shopbyError = action.payload;
    },

    calculatedOrderSheetRequest: (
      state,
      action: PayloadAction<IShopbyGetOrderSheetCalculatedInfoRequestParams>,
    ) => {},
    calculatedOrderSheetSuccess: (
      state,
      action: PayloadAction<IShopbyGetOrderSheetCalculatedInfoPayload>,
    ) => {
      state.orderSheet.data.calculatedInfo = action.payload;
    },
    calculatedOrderSheetFailure: (state, action: PayloadAction<any>) => {
      state.orderSheet.shopbyError = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<IShopbyOrderStoreListKey>) => {
      const key = action.payload;
      state.activeTabKey = key;
      state.list = initialListState;
    },

    getShopbyOrderCountSummaryRequest: (
      state,
      action: PayloadAction<{
        startYmd?: string;
        endYmd?: string;
      }>,
    ) => {},
    getShopbyOrderCountSummarySuccess: (
      state,
      action: PayloadAction<IShopbyOrderOptionCountPayload>,
    ) => {
      state.orderCountSummary.data = action.payload;
    },
    getShopbyOrderCountSummaryError: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.orderCountSummary.shopbyError = action.payload;
    },

    getShopbyOrderListRequest: (
      state,
      action: PayloadAction<IShopbyGetOrderListRequest>,
    ) => {},
    getShopbyOrderListSuccess: (
      state,
      action: PayloadAction<IShopbyGetOrdersPayload>,
    ) => {
      state.list.data =
        state.list.totalCount === 0 || !state.list.data
          ? action.payload.items
          : state.list.data.concat(action.payload.items);
      state.list.totalCount = action.payload.totalCount;
      if (action.payload.items.length > 0) state.list.paging.pageNumber += 1;
    },
    getShopbyOrderListError: (
      state,
      action: PayloadAction<any | Error | AxiosError | ITShopbyErrorRes>,
    ) => {
      state.list.shopbyError = action.payload;
    },
    getShopbyOrderListMore: (
      state,
      action: PayloadAction<TShopbyGetOrderListMoreRequest>,
    ) => {},
    resetShopbyOrderList: (state, action) => {
      state.list = initialListState;
    },
    resetError: (
      state,
      action: PayloadAction<{
        key: keyof Omit<IShopbyOrderState, 'activeTabKey'>;
      }>,
    ) => {
      state[action.payload.key].shopbyError = undefined;
    },

    getShopbyOrderDetailRequest: (
      state,
      action: PayloadAction<IShopbyGetOrderDetailRequest>,
    ) => {},
    getShopbyOrderDetailSuccess: (
      state,
      action: PayloadAction<IShopbyGetOrderDetailPayload>,
    ) => {
      state.detail.data = action.payload;
    },
    getShopbyOrderDetailError: (
      state,
      action: PayloadAction<any | AxiosError<ITShopbyErrorRes>>,
    ) => {
      state.detail.shopbyError = action.payload;
    },

    updateShopbyOrderAddressRequest: (
      state,
      action: PayloadAction<IShopbyUpdateOrderAddressRequest>,
    ) => {},
    updateShopbyOrderAddressSuccess: (
      state,
      action: PayloadAction<{
        requestPayload: IShopbyUpdateOrderAddressRequest;
      }>,
    ) => {
      const { deliveryMemo, ...extra } = action.payload.requestPayload.data;
      if (state.detail.data) {
        state.detail.data.deliveryMemo = deliveryMemo;
        state.detail.data.shippingAddress = {
          ...state.detail.data?.shippingAddress,
          ...extra,
        };
      }
      state.updateAddress.result = 'success';
    },
    updateShopbyOrderAddressError: (
      state,
      action: PayloadAction<any | AxiosError<ITShopbyErrorRes>>,
    ) => {
      state.updateAddress.shopbyError = action.payload;
    },
    resetUpdateShopbyOrderAddressState: state => {
      state.updateAddress.result = undefined;
      state.updateAddress.shopbyError = undefined;
    },

    updateShopbyConfirmOrderRequest: (
      state,
      action: PayloadAction<number>,
    ) => {},
    updateShopbyConfirmOrderSuccess: state => {
      state.confirmPurchase.result = 'success';
    },
    updateShopbyConfirmOrderError: (
      state,
      action: PayloadAction<any | AxiosError<ITShopbyErrorRes>>,
    ) => {
      state.confirmPurchase.shopbyError = action.payload;
    },
    resetUpdateShopbyConfirmOrderState: state => {
      state.confirmPurchase.result = undefined;
      state.confirmPurchase.shopbyError = undefined;
    },
  },
});

export const {
  setActiveTab,
  postShopbyOrderSheetRequest,
  postShopbyOrderSheetSuccess,
  postShopbyOrderSheetError,

  getShopbyOrderCountSummaryRequest,
  getShopbyOrderCountSummarySuccess,
  getShopbyOrderCountSummaryError,

  getShopbyOrderListRequest,
  getShopbyOrderListSuccess,
  getShopbyOrderListError,
  getShopbyOrderListMore,
  resetShopbyOrderList,

  getShopbyOrderDetailRequest,
  getShopbyOrderDetailSuccess,
  getShopbyOrderDetailError,
  resetError,

  getOrderSheetInfoRequest,
  getOrderSheetInfoSuccess,
  getOrderSheetInfoFailure,

  calculatedOrderSheetRequest,
  calculatedOrderSheetSuccess,
  calculatedOrderSheetFailure,
  updateShopbyOrderAddressRequest,
  updateShopbyOrderAddressSuccess,
  updateShopbyOrderAddressError,
  resetUpdateShopbyOrderAddressState,
  updateShopbyConfirmOrderRequest,
  updateShopbyConfirmOrderSuccess,
  updateShopbyConfirmOrderError,
  resetUpdateShopbyConfirmOrderState,
} = shopbyOrderSlice.actions;

export default shopbyOrderSlice.reducer;
