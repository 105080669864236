import { IShopbyOrderOptionCountPayload } from '@types';
import { SHOPBY_ORDER_MENU_MATCHED_REQUEST } from './shopbyConstants';

export const myReservationListLnb = {
  all: '전체',
  pending: '결제대기',
  paid: '예약완료',
  checkout: '이용완료',
  cancelled: '취소/환불',
} as const;

export const myOrderListLnb = {
  ALL: '전체',
  ORDER_OR_PAY: '주문/결제',
  PREPARE_ING: '배송 준비 중',
  DELIVERY_ING: '배송중',
  DELIVERY_DONE: '배송완료',
  BUY_CONFIRM: '구매확정',
  CANCEL_RETURN_EXCHANGE: '취소/반품/교환',
} as const;

export const SHOPBY_ORDER_MENU_MATCHED_COUNT_KEY: {
  [key in keyof typeof SHOPBY_ORDER_MENU_MATCHED_REQUEST]: (keyof IShopbyOrderOptionCountPayload)[];
} = {
  ALL: [],
  ORDER_OR_PAY: ['depositWaitCnt', 'payDoneCnt'],
  PREPARE_ING: ['productPrepareCnt', 'deliveryPrepareCnt'],
  DELIVERY_ING: ['deliveryIngCnt'],
  DELIVERY_DONE: ['deliveryDoneCnt'],
  BUY_CONFIRM: ['buyConfirmCnt'],
  CANCEL_RETURN_EXCHANGE: [
    'cancelDoneCnt',
    'cancelProcessingCnt',
    'returnDoneCnt',
    'returnProcessingCnt',
    'exchangeDoneCnt',
    'exchangeProcessingCnt',
  ],
};
