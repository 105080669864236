import { useState } from 'react';
import styled from 'styled-components';
import useInput from 'hooks/useInput';
import PageContainer from 'widgets/PageContainer';
import { ColumnContainer, RowContainer } from 'widgets/FlexBox';
import SubHeader from 'components/common/SubHeader';
import { Select } from 'components/atoms/Form';
import BasicInputOutlined from 'components/atoms/BasicInputOutlined';
import LabeledSwitch from 'components/molecules/LabeledSwitch';
import BasicTextareaOutlined from 'components/molecules/BasicTextareaOutlined';

function Inputs() {
  const { inputVal, changeInputVal, setInputVal } = useInput();
  const [isRequired, setIsRequired] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [guidePhrase, setGuidePhrase] = useState('');
  const [unSelectedOption, setUnselectedOption] = useState(false);
  const [isOptionFloating, setIsOptionFloating] = useState(true);
  const [openList, setOpenList] = useState<boolean[]>([]);

  const onChangeSelectedValue = (selectedValue: string) => {
    setInputVal(prev => {
      return {
        ...prev,
        select: selectedValue,
      };
    });
  };

  const changeIsRequired = () => {
    setIsRequired(prev => !prev);
  };

  const changeDisabled = () => {
    setDisabled(prev => !prev);
  };

  const changeGuidePhrase = () => {
    if (guidePhrase) setGuidePhrase('');
    else setGuidePhrase('가이드 문구');
  };

  const changeUnSelectedOption = () => {
    setUnselectedOption(prev => !prev);
  };

  const changeIsOptionFloating = () => {
    setIsOptionFloating(prev => !prev);
  };

  const [isTextareaMessage, setIsTextareaMessage] = useState(false);
  const [isTextCount, setIsTextCount] = useState(false);

  const changeIsTextareaMessage = () => {
    setIsTextareaMessage(!isTextareaMessage);
  };

  const changeTextCount = () => {
    setIsTextCount(!isTextCount);
  };

  return (
    <PageContainer maxWidth={false} disableGutters $backgroundColor="white">
      <SubHeader title="Inputs 컴포넌트 테스트용 페이지" />
      <Container>
        <h1 style={{ fontSize: '28px', margin: '15px 0' }}>
          〈 Input type=text 〉
        </h1>
        <h2 style={{ fontSize: '20px', margin: '15px 0' }}>
          BasicInputOutlined
        </h2>
        <BasicInputOutlined
          name="basicOutline"
          inputVal={inputVal}
          changeInputVal={changeInputVal}
        />

        <h1 style={{ fontSize: '28px', margin: '15px 0' }}>
          〈 BasicTextareaOutlined 〉
        </h1>
        <h2 style={{ fontSize: '20px', margin: '15px 0' }}>
          BasicTextareaOutlined
        </h2>
        <RowContainer
          style={{
            rowGap: '10px',
            columnGap: '5%',
            marginBottom: '16px',
            flexWrap: 'wrap',
          }}
        >
          <LabeledSwitch
            id="textareaMessage"
            labelVariant="callout"
            labelWeight="bold"
            rightLabel="isMessage"
            clickableAreaLabel
            isOn={isTextareaMessage}
            onClick={changeIsTextareaMessage}
          />
          <LabeledSwitch
            id="textCount"
            labelVariant="callout"
            labelWeight="bold"
            rightLabel="isTextCount"
            clickableAreaLabel
            isOn={isTextCount}
            onClick={changeTextCount}
          />
        </RowContainer>
        <BasicTextareaOutlined
          name="BasicTextareaOutlined"
          inputVal={inputVal}
          changeInputVal={changeInputVal}
          placeholder="placeholder placeholder "
          message={isTextareaMessage ? '도움말 메세지' : undefined}
          count={
            isTextCount
              ? inputVal.BasicTextareaOutlined
                ? inputVal.BasicTextareaOutlined.length
                : 0
              : undefined
          }
          limitCount={isTextCount ? 50 : undefined}
          maxLength={50}
          containerWidth="100%"
          width="100%"
          height="100px"
        />

        <h1 style={{ fontSize: '28px', margin: '40px 0 15px' }}>
          〈 Select 〉
        </h1>
        <h2 style={{ fontSize: '20px', margin: '15px 0' }}>Select</h2>

        <RowContainer
          style={{
            rowGap: '10px',
            columnGap: '5%',
            marginBottom: '16px',
            flexWrap: 'wrap',
          }}
        >
          <LabeledSwitch
            id="isRequired"
            labelVariant="callout"
            labelWeight="bold"
            rightLabel="isRequired"
            clickableAreaLabel
            size="small"
            isOn={isRequired}
            onClick={changeIsRequired}
          />
          <LabeledSwitch
            id="disabled"
            labelVariant="callout"
            labelWeight="bold"
            rightLabel="disabled"
            clickableAreaLabel
            size="small"
            isOn={disabled}
            onClick={changeDisabled}
          />
          <LabeledSwitch
            id="guidePhrase"
            labelVariant="callout"
            labelWeight="bold"
            rightLabel="guidePhrase"
            clickableAreaLabel
            size="small"
            isOn={!!guidePhrase}
            onClick={changeGuidePhrase}
          />
          <LabeledSwitch
            id="unSelectedOption"
            labelVariant="callout"
            labelWeight="bold"
            rightLabel="unSelectedOption"
            clickableAreaLabel
            size="small"
            isOn={!!unSelectedOption}
            onClick={changeUnSelectedOption}
          />
          <LabeledSwitch
            id="unSelectedOption"
            labelVariant="callout"
            labelWeight="bold"
            rightLabel="isOptionFloating"
            clickableAreaLabel
            size="small"
            isOn={!!isOptionFloating}
            onClick={changeIsOptionFloating}
          />
        </RowContainer>
        <ColumnContainer style={{ gap: '24px', marginBottom: '96px' }}>
          <Select.Label
            disabled={disabled}
            labelPhrase="type=contained"
            isRequired={isRequired}
            type="contained"
          >
            <Select
              key="select"
              name="select"
              value={inputVal.select}
              onChange={onChangeSelectedValue}
              type="contained"
              disabled={disabled}
              hasUnselectedOption={unSelectedOption}
              placeholder="선택해주세요"
              guideMessage={guidePhrase}
              isOpen={openList[0]}
              onClickSelect={() => {
                setOpenList(prev => {
                  return prev.map((open, idx) => {
                    if (idx === 0) return !open;
                    return open;
                  });
                });
              }}
              options={[
                { label: '1번', value: '1' },
                { label: '2번', value: '2' },
              ]}
              zPosteriority={3}
              isOptionFloating={isOptionFloating}
            />
          </Select.Label>
          <Select.Label
            disabled={disabled}
            labelPhrase="type=outlined"
            isRequired={isRequired}
            type="outlined"
          >
            <Select
              key="select"
              name="select"
              value={inputVal.select}
              onChange={onChangeSelectedValue}
              type="outlined"
              disabled={disabled}
              hasUnselectedOption={unSelectedOption}
              placeholder="선택해주세요"
              guideMessage={guidePhrase}
              isOpen={openList[1]}
              onClickSelect={() => {
                setOpenList(prev => {
                  return prev.map((open, idx) => {
                    if (idx === 1) return !open;
                    return open;
                  });
                });
              }}
              options={[
                { label: '1번', value: '1' },
                { label: '2번', value: '2' },
              ]}
              zPosteriority={2}
              isOptionFloating={isOptionFloating}
            />
          </Select.Label>
          <Select.Label
            disabled={disabled}
            labelPhrase="type=underlined"
            isRequired={isRequired}
            type="underlined"
          >
            <Select
              key="select"
              name="select"
              value={inputVal.select}
              onChange={onChangeSelectedValue}
              type="underlined"
              disabled={disabled}
              hasUnselectedOption={unSelectedOption}
              placeholder="선택해주세요"
              guideMessage={guidePhrase}
              isOpen={openList[2]}
              onClickSelect={() => {
                setOpenList(prev => {
                  return prev.map((open, idx) => {
                    if (idx === 2) return !open;
                    return open;
                  });
                });
              }}
              options={[
                { label: '1번', value: '1' },
                { label: '2번', value: '2' },
              ]}
              zPosteriority={1}
              isOptionFloating={isOptionFloating}
            />
          </Select.Label>
        </ColumnContainer>
      </Container>
    </PageContainer>
  );
}

export default Inputs;

const Container = styled.div`
  padding: 16px;
`;
