import axios, { AxiosRequestConfig } from 'axios';
import { getShopbyServerURL } from 'server';
import {
  IGetShopbyMyReviewListRequestPayload,
  IGetShopbyReviewDetailRequestPayload,
  IGetShopbyReviewableItemListRequestPayload,
  IPostShopbyReviewableItemRequestPayload,
  IUpdateShopbyMyReviewRequestPayload,
} from 'store/types';
import {
  IShopbyGetOrderDetailRequest,
  IShopbyGetOrderListRequest,
  IShopbyPostOrderSheet,
  IShopbyRevewListRequest,
  IShopbyUpdateOrderAddressRequest,
  ICreateShopbyUserAddressRequestPayload,
  ICreateShopbyCancelClaimsRequestPayload,
  IEstimateShopbyClaimPriceRequestPayload,
  IShopbyGetOrderClaimInfoRequest,
  IShopbyZipCodeRequestPayload,
  IGetShopbyMyInquiryRequestPayload,
  IPostShopbyItemInquiryRequestPayload,
  IEditShopbyItemInquiryRequestPayload,
  IEstimateShopbyClaimPriceForOneRequestPayload,
  ICreateShopbyReturnClaimsRequestPayload,
  ICreateShopbyExchangeClaimsRequestPayload,
} from '../@types/index';

const provider = 'ncpstore';

const shopbyBaseApi = axios.create({
  baseURL: getShopbyServerURL(),
  headers: {
    accept: 'application/json',
    Version: '1.0',
    clientId: process.env.REACT_APP_SHOPBY_CLIENT_ID,
    platform: 'PC',
    'Cache-Control': 'no-cache',
  },
});

const shopbyBaseApiWithAccessToken = axios.create({
  baseURL: getShopbyServerURL(),
  headers: {
    accept: 'application/json',
    Version: '1.0',
    clientId: process.env.REACT_APP_SHOPBY_CLIENT_ID,
    platform: 'PC',
    'Cache-Control': 'no-cache',
  },
});

export const getShopbyAccessToken = async () => {
  let jwt = localStorage.getItem('jwt');

  if (jwt) {
    jwt = jwt.replace(/"/g, '');
  }
  if (!jwt) {
    throw new Error('로그인 되지 않음');
  }
  const res = await shopbyBaseApi.get<any>(
    `/oauth/openid?provider=${provider}&keepLogin=true&openAccessToken=Bearer ${jwt}`,
  );

  return res;
};

shopbyBaseApiWithAccessToken.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let shopbyAccessToken: string | null | undefined =
      localStorage.getItem('shopbyAccessToken');

    if (shopbyAccessToken) {
      shopbyAccessToken = shopbyAccessToken.replace(/"/g, '');
    } else {
      const res = await getShopbyAccessToken();
      if (!res.data?.accessToken || res.data?.expireIn <= 0) {
        throw new Error('샵바이 accessToken 획득 실패');
      }
      if (res.data?.expireIn <= 0) {
        throw new Error('샵바이 accessToken 유효기간 만료');
      }
      localStorage.setItem('shopbyAccessToken', res?.data?.accessToken);
      shopbyAccessToken = res?.data?.accessToken;
    }

    config.headers.accessToken = shopbyAccessToken
      ? `${shopbyAccessToken}`
      : '';
    return config;
  },
  async error => {
    console.log('error', error);
    return Promise.reject(error);
  },
);

shopbyBaseApiWithAccessToken.interceptors.response.use(
  response => response,
  error => {
    //   if (error.response.status === 401) {
    //     if (localStorage.getItem('jwt')) {
    //       alert('세션이 만료되었습니다. 다시 로그인 해주세요.');
    //       store.dispatch(clickLogout(!!window.BootpayRNWebView));
    //     }
    //   }
    //   if (error.response.status === 403) {
    //     alert('권한이 없습니다. 로그인이 필요합니다.');
    //     // NOTE: login 페이지로 했을 때 무한 alert 현상 생긴 사례가 있어 현재 mypage로 유지 > 추후 확인해서 픽스 후 수정해야할 듯
    //     window.location.href = '/mypage';
    //   }
    //   return Promise.reject(error);
    return Promise.reject(error);
  },
);

export const getNullalbeShopbyAccessToken = async () => {
  let jwt = localStorage.getItem('jwt');

  if (!jwt) {
    return;
  }

  jwt = jwt.replace(/"/g, '');

  const res = await shopbyBaseApi.get<any>(
    `/oauth/openid?provider=${provider}&keepLogin=true&openAccessToken=Bearer ${jwt}`,
  );

  return res;
};

export const getMalls = async () => {
  const res = await shopbyBaseApi.get('/malls');

  return res.data;
};

export const getItemOption = async (itemNo: string) => {
  const res = await shopbyBaseApi.get(`/products/${itemNo}/options`);
  return res.data;
};

export const getOrderSheetNo = async (params: IShopbyPostOrderSheet) => {
  const res = await shopbyBaseApiWithAccessToken.post('/order-sheets', params);
  return res.data;
};

export const getShopbyUserAddress = async () => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/shipping-addresses',
  );

  return res.data;
};

export const createShopbyUserAddress = async (
  params: ICreateShopbyUserAddressRequestPayload,
) => {
  // NOTE. 샵바이 API 명세와 다른 부분이 있어 추가함 , 2023.04.27, 이양우
  const body = {
    ...params,
    addressType: 'BOOK',
    receiverContact2: '',
    customsIdNumber: '',
  };

  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/shipping-addresses`,
    body,
  );

  return res.data;
};

export const updateShopbyUserAddress = async (params: {
  addressNo: string;
  addressInfo: ICreateShopbyUserAddressRequestPayload;
}) => {
  const { addressNo, addressInfo } = params;

  const body = {
    ...addressInfo,
    addressType: 'BOOK',
    receiverContact2: '',
    customsIdNumber: '',
  };

  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/shipping-addresses/${addressNo}`,
    body,
  );

  return res.data;
};

export const setDefalutShopbyUserAddress = async (addressNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/shipping-addresses/${addressNo}/default`,
  );

  return res.data;
};

export const deleteShopbyUserAddress = async (addressNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.delete(
    `/profile/shipping-addresses/${addressNo}`,
  );

  return res.data;
};

export const getShopbyOrderCountSummary = async (params: {
  startYmd?: string;
  endYmd?: string;
}) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/order-options/summary/status',
    {
      params,
    },
  );

  return res.data;
};

export const getShopbyMyOrders = async (params: IShopbyGetOrderListRequest) => {
  const res = await shopbyBaseApiWithAccessToken.get('/profile/orders', {
    params,
  });
  return res.data;
};

export const getShopbyMyOrderDetail = async (
  params: IShopbyGetOrderDetailRequest,
) => {
  const { orderNo, orderRequestType } = params;
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/orders/${orderNo}`,
    { params: { orderRequestType } },
  );

  return res.data;
};

export const getShopbyItemReviewList = async (
  params: IShopbyRevewListRequest,
) => {
  const { productNo, parameters } = params;

  let urlParams = '';

  for (const [key, value] of Object.entries(parameters)) {
    if (key === 'orderDirertion') {
      urlParams += `order.direction=${value}&`;
    } else if (key === 'orderBy') {
      urlParams += `order.by=${value}&`;
    } else if (value) {
      urlParams += `${key}=${value}&`;
    }
  }

  const res = await shopbyBaseApiWithAccessToken.get(
    `/products/${productNo}/product-reviews?${urlParams}`,
  );

  return res.data;
};

export const getOrderSheetInfo = async (orderSheetNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/order-sheets/${orderSheetNo}`,
  );

  return res.data;
};
export const calculatedOrderSheet = async (params: any) => {
  const { orderSheetNo, data: payload } = params;

  const res = await shopbyBaseApiWithAccessToken.post(
    `/order-sheets/${orderSheetNo}/calculate`,
    payload,
  );

  return res.data;
};

export const updateShopbyOrderAddress = async (
  params: IShopbyUpdateOrderAddressRequest,
) => {
  const { orderNo, data: payload } = params;
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/orders/${orderNo}/deliveries`,
    payload,
  );

  return res.data;
};

export const updateShopbyPurchaseConfirm = async (orderOptionNo: number) => {
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/order-options/${orderOptionNo}/confirm`,
  );

  return res.data;
};
export const getShopbyInfoForClaim = async (
  params: IShopbyGetOrderClaimInfoRequest,
) => {
  const { orderOptionNo, queryData: payload } = params;
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/order-options/${orderOptionNo}/claims`,
    { params: payload },
  );

  return res.data;
};

export const estimateShopbyPriceInfoForClaim = async (
  payload: IEstimateShopbyClaimPriceRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/claims/estimate`,
    payload,
  );

  return res.data;
};

export const estimateShopbyPriceInfoForOneOptionClaim = async (
  params: IEstimateShopbyClaimPriceForOneRequestPayload,
) => {
  const { orderOptionNo, ...payload } = params;
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/order-options/${orderOptionNo}/claims/estimate`,
    { params: payload },
  );

  return res.data;
};

export const postShopbyClaim = async (
  payload:
    | ICreateShopbyCancelClaimsRequestPayload
    | ICreateShopbyReturnClaimsRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/claims/${payload.claimType.toLowerCase()}`,
    payload,
  );

  return res.data;
};

export const postShopbyExchangeClaim = async (
  payload: ICreateShopbyExchangeClaimsRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.post(
    `/profile/order-options/${payload.orderOptionNo}/claims/exchange`,
    payload,
  );

  return res.data;
};

export const getShopbyClaimDetailByClaimNo = async (claimNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/claims/${claimNo}/result`,
  );

  return res.data;
};

export const getShopbyClaimDetailByOrderOptionNo = async (
  orderOptionNo: string,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/order-options/${orderOptionNo}/claims/result`,
  );

  return res.data;
};

export const checkIsShopbyClaimWithdrawable = async (claimNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    `/profile/claims/${claimNo}/check-withdraw`,
  );

  return res.data;
};

export const withdrawShopbyClaim = async (claimNo: string) => {
  const res = await shopbyBaseApiWithAccessToken.put(
    `/profile/claims/${claimNo}/withdraw`,
  );

  return res.data;
};

export const getShopbySearchZipCode = async (
  params: IShopbyZipCodeRequestPayload,
) => {
  const res = await shopbyBaseApi.get(`/addresses/search`, { params });

  return res.data;
};

export const uploadShopbyImageFile = async (data: FormData) => {
  const res = await shopbyBaseApi.post('/files/images ', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      accept: 'multipart/form-data',
    },
  });

  return res.data;
};

export const uploadShopbyImageFiles = async (data: FormData[]) => {
  const responseData = await Promise.all(
    data.map(formData => {
      return new Promise(resolve => {
        const imageUrlData = uploadShopbyImageFile(formData);
        resolve(imageUrlData);
      });
    }),
  );

  return responseData;
};

export const getShopbyMyInquiry = async (
  params: IGetShopbyMyInquiryRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/product-inquiries',
    { params },
  );

  return res.data;
};

export const postShopbyMyInquiry = async (
  params: IPostShopbyItemInquiryRequestPayload,
) => {
  const { productNo } = params;
  const res = await shopbyBaseApiWithAccessToken.post(
    `/products/${productNo}/inquiries`,
    params,
  );

  return res.data;
};

export const editShopbyMyInquiry = async (params: {
  inquiryNo: number;
  data: IEditShopbyItemInquiryRequestPayload;
}) => {
  const { inquiryNo, data } = params;

  const res = await shopbyBaseApiWithAccessToken.put(
    `/products/inquiries/${inquiryNo}`,
    data,
  );

  return res.data;
};

export const deleteShopbyMyInquiry = async (inquiryNo: number) => {
  const res = await shopbyBaseApiWithAccessToken.delete(
    `/products/inquiries/${inquiryNo}`,
  );

  return res.data;
};

export const getShopbyMyReviewableList = async (
  params: IGetShopbyReviewableItemListRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/order-options/product-reviewable',
    { params },
  );

  return res.data;
};

export const postShopbyMyItemReview = async (
  params: IPostShopbyReviewableItemRequestPayload,
) => {
  const { productNo, ...body } = params;

  const res = await shopbyBaseApiWithAccessToken.post(
    `/products/${productNo}/product-reviews`,
    body,
  );

  return res.data;
};

export const getShopbyMyReviewedList = async (
  params: IGetShopbyMyReviewListRequestPayload,
) => {
  const res = await shopbyBaseApiWithAccessToken.get(
    '/profile/product-reviews',
    { params },
  );

  return res.data;
};

export const getShopbyReviewDetail = async (
  params: IGetShopbyReviewDetailRequestPayload,
) => {
  const { productNo, reviewNo } = params;

  const res = await shopbyBaseApiWithAccessToken.get(
    `/products/${productNo}/product-reviews/${reviewNo}`,
  );

  return res.data;
};

export const updateShopbyMyItemReview = async (
  params: IUpdateShopbyMyReviewRequestPayload,
) => {
  const { productNo, reviewNo, ...body } = params;

  const res = await shopbyBaseApiWithAccessToken.put(
    `/products/${productNo}/product-reviews/${reviewNo}`,
    body,
  );

  return res.data;
};
