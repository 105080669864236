import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TShopbyUserAddressState } from 'store/types';
import {
  ICreateShopbyUserAddressRequestPayload,
  IShopByUserAddress,
  ITShopbyErrorRes,
} from '@types';

const initialState: TShopbyUserAddressState = {
  recentAddressList: [],
  bookedAddressList: [],
  defaultAddress: {} as IShopByUserAddress,
  count: 0,
  shopbyError: undefined,
};

export const shopbyUserAddressSlice = createSlice({
  name: 'shopbyUserAddress',
  initialState,
  reducers: {
    getShopbyUserAddressListRequest: state => {
      state.bookedAddressList = [];
      state.recentAddressList = [];
      state.defaultAddress = {} as IShopByUserAddress;
      state.shopbyError = undefined;
    },
    getShopbyUserAddressListSuccess: (
      state,
      action: PayloadAction<{
        bookedAddresses: IShopByUserAddress[];
        recentAddresses: IShopByUserAddress[];
        defaultAddress: IShopByUserAddress;
      }>,
    ) => {
      const { bookedAddresses, recentAddresses, defaultAddress } =
        action.payload;

      state.bookedAddressList = bookedAddresses;
      state.recentAddressList = recentAddresses;
      state.defaultAddress = defaultAddress;
      state.count = bookedAddresses.length;
      state.shopbyError = undefined;
    },
    getShopbyUserAddressListFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    createShopbyUserAddressRequest: (
      state,
      action: PayloadAction<ICreateShopbyUserAddressRequestPayload>,
    ) => {
      state.shopbyError = undefined;
    },
    createShopbyUserAddressSuccess: (state, action) => {
      state.shopbyError = undefined;
    },
    createShopbyUserAddressFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    updateShopbyUserAddressRequest: (
      state,
      action: PayloadAction<{
        addressNo: number;
        addressInfo: ICreateShopbyUserAddressRequestPayload;
      }>,
    ) => {
      state.shopbyError = undefined;
    },
    updateShopbyUserAddressSuccess: (state, action) => {
      state.shopbyError = undefined;
    },
    updateShopbyUserAddressFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    setDefaultShopbyUserAddressRequest: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.shopbyError = undefined;
    },
    setDefaultShopbyUserAddressSuccess: (
      state,
      action: PayloadAction<IShopByUserAddress>,
    ) => {
      state.defaultAddress = action.payload;
      state.shopbyError = undefined;
    },
    setDefaultShopbyUserAddressFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    deleteShopbyUserAddressRequest: (state, action: PayloadAction<number>) => {
      state.shopbyError = undefined;
    },
    deleteShopbyUserAddressSuccess: (state, action: PayloadAction) => {
      state.shopbyError = undefined;
    },
    deleteShopbyUserAddressFailure: (
      state,
      action: PayloadAction<ITShopbyErrorRes>,
    ) => {
      state.shopbyError = action.payload;
    },

    updateShopbyUserAddressCardList: (
      state,
      action: PayloadAction<{
        option: 'delete' | 'setDefaultAddress';
        targetAddressNo: number;
        subTargetAddressNo?: number;
      }>,
    ) => {
      const { option, targetAddressNo, subTargetAddressNo } = action.payload;

      const targetAddressCardIndex = state.bookedAddressList.findIndex(
        address => address.addressNo === targetAddressNo,
      );

      const subTargetAddressCardIndex = state.bookedAddressList.findIndex(
        address => address.addressNo === subTargetAddressNo,
      );

      if (targetAddressCardIndex === -1) {
        return;
      }

      if (option === 'setDefaultAddress') {
        if (subTargetAddressCardIndex === -1) {
          return;
        }

        const copyAddressList = [...state.bookedAddressList];
        copyAddressList[targetAddressCardIndex].defaultYn = 'Y';
        copyAddressList[subTargetAddressCardIndex].defaultYn = 'N';

        state.bookedAddressList = [...copyAddressList];
      }

      if (option === 'delete') {
        const copyAddressList = [...state.bookedAddressList];
        copyAddressList.splice(targetAddressCardIndex, 1);

        state.bookedAddressList = [...copyAddressList];
        state.count -= 1;
      }
    },
  },
});

export const {
  getShopbyUserAddressListRequest,
  getShopbyUserAddressListSuccess,
  getShopbyUserAddressListFailure,

  createShopbyUserAddressRequest,
  createShopbyUserAddressSuccess,
  createShopbyUserAddressFailure,

  updateShopbyUserAddressRequest,
  updateShopbyUserAddressSuccess,
  updateShopbyUserAddressFailure,

  setDefaultShopbyUserAddressRequest,
  setDefaultShopbyUserAddressSuccess,
  setDefaultShopbyUserAddressFailure,

  deleteShopbyUserAddressRequest,
  deleteShopbyUserAddressSuccess,
  deleteShopbyUserAddressFailure,

  updateShopbyUserAddressCardList,
} = shopbyUserAddressSlice.actions;

export default shopbyUserAddressSlice.reducer;
