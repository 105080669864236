/* eslint-disable prefer-rest-params */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable class-methods-use-this */
declare global {
  interface Window {
    ChannelIO?: IChannelIO;
    ChannelIOInitialized?: boolean;
  }
}

interface IChannelIO {
  c?: (...args: any) => void;
  q: [methodName: string, ...args: any[]][];
  (...args: any): void;
}

interface BootOption {
  appearance?: string;
  customLauncherSelector?: string;
  hideChannelButtonOnBoot?: boolean;
  hidePopup?: boolean;
  language?: string;
  memberHash?: string;
  memberId?: string;
  mobileMessengerMode?: string;
  pluginKey: string;
  profile?: Profile;
  trackDefaultEvent?: boolean;
  trackUtmSource?: boolean;
  unsubscribe?: boolean;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
  zIndex?: number;
}

interface Callback {
  (error: Error | null, user: CallbackUser | null): void;
}

interface CallbackUser {
  alert: number;
  avatarUrl: string;
  id: string;
  language: string;
  memberId: string;
  name?: string;
  profile?: Profile | null;
  tags?: string[] | null;
  unsubscribeEmail: boolean;
  unsubscribeTexting: boolean;
}

interface UpdateUserInfo {
  language?: string;
  profile?: Profile | null;
  profileOnce?: Profile;
  tags?: string[] | null;
  unsubscribeEmail?: boolean;
  unsubscribeTexting?: boolean;
}

interface Profile {
  [key: string]: string | number | boolean | null;
}

interface FollowUpProfile {
  name?: string | null;
  mobileNumber?: string | null;
  email?: string | null;
}

interface EventProperty {
  [key: string]: string | number | boolean | null;
}

type Appearance = 'light' | 'dark' | 'system' | null;

class ChannelTalkService {
  isChatOpen: boolean = false;

  prevPage: string = '/';

  constructor() {
    this.loadScript();
  }

  loadScript() {
    (function () {
      const w = window;
      if (w.ChannelIO) {
        return w.console.error('ChannelIO script included twice.');
      }
      var ch = function () {
        ch.c && ch.c(arguments);
      } as IChannelIO;
      ch.q = [];
      ch.c = function (args: any) {
        ch.q.push(args);
      };
      w.ChannelIO = ch;
      function l() {
        if (w.ChannelIOInitialized) {
          return;
        }
        w.ChannelIOInitialized = true;
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        const x = document.getElementsByTagName('script')[0];
        if (x.parentNode) {
          x.parentNode.insertBefore(s, x);
        }
      }
      if (document.readyState === 'complete') {
        l();
      } else {
        w.addEventListener('DOMContentLoaded', l);
        w.addEventListener('load', l);
      }
    })();
  }

  boot(option: BootOption, callback?: Callback) {
    window.ChannelIO?.('boot', option, callback);
  }

  setIsChatOpen(isChatOpen: boolean) {
    this.isChatOpen = isChatOpen;
  }

  shutdown() {
    window.ChannelIO?.('shutdown');
  }

  showMessenger() {
    window.ChannelIO?.('showMessenger');
  }

  hideMessenger() {
    window.ChannelIO?.('hideMessenger');
  }

  openChat(chatId?: string | number, message?: string) {
    this.isChatOpen = true;
    window.ChannelIO?.('openChat', chatId, message);
  }

  track(eventName: string, eventProperty?: EventProperty) {
    window.ChannelIO?.('track', eventName, eventProperty);
  }

  onShowMessenger(callback: () => void) {
    window.ChannelIO?.('onShowMessenger', callback);
  }

  onHideMessenger(callback: () => void) {
    window.ChannelIO?.('onHideMessenger', callback);
  }

  onBadgeChanged(callback: (alert: number) => void) {
    window.ChannelIO?.('onBadgeChanged', (alert: number) => {
      callback(alert);
    });
  }

  onChatCreated(callback: () => void) {
    window.ChannelIO?.('onChatCreated', callback);
  }

  onFollowUpChanged(callback: (profile: FollowUpProfile) => void) {
    window.ChannelIO?.('onFollowUpChanged', callback);
  }

  onUrlClicked(callback: (url: string) => void) {
    window.ChannelIO?.('onUrlClicked', callback);
  }

  clearCallbacks() {
    window.ChannelIO?.('clearCallbacks');
  }

  updateUser(userInfo: UpdateUserInfo, callback?: Callback) {
    window.ChannelIO?.('updateUser', userInfo, callback);
  }

  addTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.('addTags', tags, callback);
  }

  removeTags(tags: string[], callback?: Callback) {
    window.ChannelIO?.('removeTags', tags, callback);
  }

  setPage(page: string) {
    // NOTE: setPage 조건 운영팀 문서 확인 후 사용
    window.ChannelIO?.('setPage', page);
    this.prevPage = page;
  }

  resetPage() {
    window.ChannelIO?.('resetPage');
  }

  showChannelButton() {
    window.ChannelIO?.('showChannelButton');
  }

  hideChannelButton() {
    window.ChannelIO?.('hideChannelButton');
  }

  setAppearance(appearance: Appearance) {
    window.ChannelIO?.('setAppearance', appearance);
  }

  customOpenChannelTalk(channelTalkPage: string) {
    // 같은 화면 내에 현재 열려있는, 같은 page 채널톡일 경우 다시 열지 않음.
    if (this.prevPage === channelTalkPage && this.isChatOpen) {
      return;
    }
    // 기존에 채널톡 창이 열려 있을 경우
    if (this.isChatOpen) this.hideMessenger();

    // 처음 채널톡 열 때, 해당 페이지의 서포트봇이 적용된 기본 라운지 열기
    if (this.prevPage === '/') {
      this.setPage(channelTalkPage);
      this.showMessenger();
      return;
    }

    // 기존에 열려 있는 채널 톡이 있을 경우, 새로운 서포트봇이 적용된 라운지를 열기 위한 openChat
    this.setPage(channelTalkPage);
    this.openChat(0);
  }
}

export default new ChannelTalkService();
