import { AxiosError } from 'axios';
import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import {
  postShopbyOrderSheetRequest,
  postShopbyOrderSheetSuccess,
  postShopbyOrderSheetError,
  getOrderSheetInfoRequest,
  getOrderSheetInfoSuccess,
  getOrderSheetInfoFailure,
  calculatedOrderSheetRequest,
  calculatedOrderSheetSuccess,
  calculatedOrderSheetFailure,
  getShopbyOrderListRequest,
  getShopbyOrderListError,
  getShopbyOrderListSuccess,
  getShopbyOrderListMore,
  getShopbyOrderCountSummaryRequest,
  getShopbyOrderCountSummarySuccess,
  getShopbyOrderCountSummaryError,
  getShopbyOrderDetailRequest,
  getShopbyOrderDetailSuccess,
  getShopbyOrderDetailError,
  updateShopbyOrderAddressError,
  updateShopbyOrderAddressRequest,
  updateShopbyOrderAddressSuccess,
  updateShopbyConfirmOrderRequest,
  updateShopbyConfirmOrderSuccess,
  updateShopbyConfirmOrderError,
} from 'store/reducers/shopbyOrder';
import {
  ITShopbyErrorRes,
  IShopbyPostOrderSheetPayload,
  TShopbyGetOrderListMoreRequest,
} from '@types';
import { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { IShopbyOrderStoreListKey } from 'store/types';
import { toast } from 'react-toastify';
import { customHistory } from '../../App';
import { createShopbyFetchAction } from './createFetchAction';
import {
  getOrderSheetNo,
  getShopbyMyOrders,
  calculatedOrderSheet,
  getOrderSheetInfo,
  getShopbyOrderCountSummary,
  getShopbyMyOrderDetail,
  updateShopbyOrderAddress,
  updateShopbyPurchaseConfirm,
} from '../../api/shopby';

function* postShopbyOrderSheetSaga() {
  yield takeEvery(
    postShopbyOrderSheetRequest.type,
    createShopbyFetchAction(
      getOrderSheetNo,
      postShopbyOrderSheetSuccess,
      postShopbyOrderSheetError,
      undefined,
      function onSuccess(data: IShopbyPostOrderSheetPayload) {
        customHistory.push(`/store/ordersheet/${data.orderSheetNo}`);
      },
    ),
  );
}

function* getOrderSheetInfoSaga() {
  yield takeEvery(
    getOrderSheetInfoRequest.type,
    createShopbyFetchAction(
      getOrderSheetInfo,
      getOrderSheetInfoSuccess,
      getOrderSheetInfoFailure,
      undefined,
    ),
  );
}

function* calculatedOrderSheetSaga() {
  yield takeEvery(
    calculatedOrderSheetRequest.type,
    createShopbyFetchAction(
      calculatedOrderSheet,
      calculatedOrderSheetSuccess,
      calculatedOrderSheetFailure,
      undefined,
    ),
  );
}

function* orderCountSummarySaga() {
  yield takeEvery(
    getShopbyOrderCountSummaryRequest.type,
    createShopbyFetchAction(
      getShopbyOrderCountSummary,
      getShopbyOrderCountSummarySuccess,
      getShopbyOrderCountSummaryError,
      undefined,
    ),
  );
}

function* getShopbyOrderListSaga() {
  yield takeEvery(
    [getShopbyOrderListRequest.type, getShopbyOrderListMore.type],
    createShopbyFetchAction(
      getShopbyMyOrders,
      getShopbyOrderListSuccess,
      getShopbyOrderListError,
      undefined,
      undefined,
      function onError(errorPayload: ITShopbyErrorRes) {
        alert(errorPayload.message);
      },
    ),
  );
}

// function* getShopbyOrderListMoreSaga() {
//   while (true) {
//     const action: PayloadAction<{
//       key: IShopbyOrderStoreListKey;
//       params: TShopbyGetOrderListMoreRequest;
//     }> = yield take(getShopbyOrderListMore.type);

//     const {
//       payload: { params },
//     } = action;

//     const {
//       list: { paging },
//     } = yield select((state: RootState) => state.shopbyOrderReducer);

//     yield put(
//       getShopbyOrderListRequest({ ...paging, params, hasTotalCount: false }),
//     );
//   }
// }

function* getShopbyOrderDetailSaga() {
  yield takeEvery(
    getShopbyOrderDetailRequest.type,
    createShopbyFetchAction(
      getShopbyMyOrderDetail,
      getShopbyOrderDetailSuccess,
      getShopbyOrderDetailError,
      undefined,
      undefined,
      function onError(errorPayload: ITShopbyErrorRes) {
        alert(errorPayload.message);
      },
    ),
  );
}

function* updateShopbyOrderAddressSaga() {
  yield takeEvery(
    updateShopbyOrderAddressRequest.type,
    createShopbyFetchAction(
      updateShopbyOrderAddress,
      updateShopbyOrderAddressSuccess,
      updateShopbyOrderAddressError,
      undefined,
      undefined,
    ),
  );
}

function* updateShopbyPurchaseConfirmSaga() {
  yield takeEvery(
    updateShopbyConfirmOrderRequest.type,
    createShopbyFetchAction(
      updateShopbyPurchaseConfirm,
      updateShopbyConfirmOrderSuccess,
      updateShopbyConfirmOrderError,
      undefined,
      undefined,
    ),
  );
}

export default function* shopbyOrderSaga() {
  yield all([
    getOrderSheetInfoSaga(),
    postShopbyOrderSheetSaga(),
    calculatedOrderSheetSaga(),
    getOrderSheetInfoSaga(),
    orderCountSummarySaga(),
    getShopbyOrderListSaga(),
    // getShopbyOrderListMoreSaga(),
    getShopbyOrderDetailSaga(),
    updateShopbyOrderAddressSaga(),
    updateShopbyPurchaseConfirmSaga(),
  ]);
}
