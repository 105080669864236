import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { CSSProp, css } from 'styled-components';
import { theme } from 'static/styles/theme';
import { SmallChevronRightIcon } from 'static/newIcons/outline/regular';
import { TOptionDefault } from '@types';
import Typography from '../Typography';

export type TSelect = 'contained' | 'outlined' | 'underlined';

export interface ISelectProps {
  name: string;
  onChange?: (value: string, index?: number) => void;
  zPosteriority?: number; // select가 동시에 여러개 사용될 때, 겹침 현상 방지를 위한 z-index를 자동 부여를 위한 값. 우선순위 의미의 반대로 생각하면 됨.
  // 최상단 select부터 ~ 최하단selectd 순으로 가장 높은 숫자부터 입력. 예) 최상단(쌓임 select 개수 중 가장 큰 수)~최하단(0 혹은 1)
  type?: TSelect;
  value?: string;
  isOpen?: boolean;
  autoOpen?: boolean;
  placeholder?: string;
  hasUnselectedOption?: boolean; // options를 따로 내려서 사용하는 경우에만 유효한 속성
  disabled?: boolean;
  guideMessage?: string;
  error?: {
    message: string;
  };
  $width?: string;
  cssObject?: CSSProp;
  options?: TOptionDefault[];
  children?: ReactNode;
  zIndex?: number;
  optionDirection?: 'up' | 'down';
  isOptionFloating?: boolean;
  onClickSelect?: () => void;
  isOnlyValueLabel?: boolean;
  maxHeight?: string;
}

interface ISelectLabel {
  isRequired?: boolean;
  labelPhrase: string;
  children: ReactNode;
  disabled?: boolean;
  type: TSelect;
}

function Select(props: ISelectProps) {
  const {
    cssObject,
    onChange,
    type = 'outlined',
    children,
    placeholder,
    isOpen = false,
    autoOpen = true,
    disabled = false,
    options,
    error,
    guideMessage,
    hasUnselectedOption = false,
    zIndex,
    zPosteriority = 0,
    optionDirection = 'down',
    isOptionFloating = true,
    onClickSelect,
    isOnlyValueLabel,
    maxHeight,
    ...selectProps
  } = props;

  const [open, setOpen] = useState<boolean>(isOpen);
  const selectRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    open && selectRef.current?.scrollTo({ top: 0 });
  }, [open]);

  const onClickSelectBox = (
    e: MouseEvent<HTMLDivElement | HTMLUListElement>,
  ) => {
    e.preventDefault();
    onClickSelect && onClickSelect();
    autoOpen && !disabled && setOpen(prev => !prev);
  };

  const onChangeSelectValue =
    (value: string, index?: number) => (e: MouseEvent<HTMLLIElement>) => {
      e.preventDefault();
      onChange && (index ? onChange(value, index) : onChange(value));
    };

  const getSelectOptions = () => {
    if (!options) return [];
    let selectOptions = options;

    if (hasUnselectedOption) {
      selectOptions = Array.from(options);
      selectOptions.unshift({ label: '미선택', value: '' });
    }

    return selectOptions;
  };

  const selectedOption = getSelectOptions()?.find(
    option => option.value === selectProps.value,
  );

  return (
    <Container
      type={type}
      isError={!!error}
      active={open}
      hasInfoMessage={!!guideMessage || !!error?.message}
      css={cssObject}
      zPosteriority={zPosteriority * 2}
    >
      <Typography
        tagName="button"
        variant="callout2"
        weight="medium"
        // NOTE: 미선택 시, black 색상 표기 원하면 selectedOption.label 로, grey 색상 표기 원하면 selectedOption.value 로 분기
        color={
          disabled
            ? 'lightGrey2'
            : selectedOption?.value || (isOnlyValueLabel && selectProps.value)
            ? 'black'
            : 'grey1'
        }
        onClick={onClickSelectBox}
      >
        {isOnlyValueLabel
          ? selectProps.value || placeholder || '선택해주세요'
          : selectedOption?.label || placeholder || '선택해주세요'}
        <SmallChevronRightIcon
          width={24}
          height={24}
          fill={theme.newColors.grey4}
          className={open ? 'chevron-up' : 'chevron-down'}
        />
      </Typography>

      <SelectContainer
        type={type}
        isError={!!error}
        active={open}
        onClick={!disabled ? onClickSelectBox : undefined}
        disabled={disabled}
        zPosteriority={zPosteriority * 2}
        isOptionFloating={isOptionFloating}
        maxHeight={maxHeight}
        ref={selectRef}
      >
        <Typography
          tagName="button"
          variant="callout2"
          weight="medium"
          // NOTE: 미선택 시, black 색상 표기 원하면 selectedOption.label 로, grey 색상 표기 원하면 selectedOption.value 로 분기
          color={
            disabled ? 'lightGrey2' : selectedOption?.value ? 'black' : 'grey1'
          }
        >
          {selectedOption?.label || placeholder || '선택해주세요'}
          <SmallChevronRightIcon
            width={24}
            height={24}
            fill={theme.newColors.grey4}
            className={open ? 'chevron-up' : 'chevron-down'}
          />
        </Typography>
        {getSelectOptions()?.map((option, index) => (
          <Typography
            key={option.value}
            tagName="li"
            variant="callout2"
            weight="medium"
            onClick={
              !disabled ? onChangeSelectValue(option.value, index) : undefined
            }
          >
            {option.label}
          </Typography>
        ))}
        {children && children}
      </SelectContainer>

      {guideMessage && (
        <Typography
          tagName="p"
          variant="footnote"
          weight="regular"
          color={disabled ? 'lightGrey2' : 'grey2'}
          cssObject={css`
            z-index: ${open ? -1 : zPosteriority * 2 + 549};
            position: absolute;
            top: 57px;
            left: ${type === 'underlined' ? '0' : '8'}px;
          `}
        >
          {guideMessage}
        </Typography>
      )}
    </Container>
  );
}

Select.Label = (props: ISelectLabel) => {
  const { children, labelPhrase, isRequired, disabled, type } = props;
  return (
    <label>
      <Typography
        variant="footnote"
        weight="regular"
        cssObject={css`
          margin-left: ${type === 'underlined' ? '0' : '8'}px;
          > b {
            font-weight: 400;
            color: ${disabled
              ? theme.newColors.lightGrey2
              : theme.newColors.red};
            margin-left: -2px;
          }
          margin-bottom: 4px;
        `}
        color={disabled ? 'lightGrey2' : 'black'}
      >
        {labelPhrase} {isRequired && <b>*</b>}
      </Typography>
      {children}
    </label>
  );
};

interface ISelectContainerStyle {
  type: TSelect;
  isError?: boolean;
  active: boolean;
  disabled?: boolean;
  zPosteriority: number;
  isOptionFloating?: boolean;
  maxHeight?: string;
}

interface ISelectStyle extends ISelectContainerStyle {
  css?: CSSProp;
  hasInfoMessage?: boolean;
  $width?: string;
}

const Container = styled.div<ISelectStyle>`
  min-height: 48px;
  margin-bottom: ${({ hasInfoMessage, active }) =>
    hasInfoMessage && !active ? '33px' : '0px'};
  position: relative;
  z-index: ${({ zPosteriority }) => zPosteriority + 550};

  & > button {
    position: absolute;
    z-index: ${({ zPosteriority }) => zPosteriority + 550};
    cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
    display: flex;
    justify-content: space-between;
    height: 48px;
    align-items: center;
    width: auto;
    padding: ${({ type }) =>
      type === 'underlined'
        ? '13.5px 30px 13.5px 4px'
        : '13.5px 42px 13.5px 16px'};
    border-bottom: ${({ type }) =>
      type === 'underlined' && `1.5px solid ${theme.newColors.lightGrey2}`};
    width: 100%;

    ${({ type, isError }) => getSelectCss(type, !!isError)};

    svg {
      position: absolute;
      right: ${({ type }) => (type === 'underlined' ? '0px' : '12px')};
      transition: 0.3s;

      &.chevron-down {
        transform: rotate(90deg);
      }

      &.chevron-up {
        transform: rotate(-90deg);
      }
    }
  }
  ${({ css }) => css && css};
`;

const SelectContainer = styled.ul<ISelectContainerStyle>`
  display: block;
  position: ${({ isOptionFloating }) =>
    isOptionFloating ? 'absolute' : 'relative'};
  width: 100%;
  max-height: ${({ active, maxHeight }) =>
    !active ? '48px' : maxHeight && maxHeight};
  overflow-y: ${({ maxHeight }) => maxHeight && 'auto'};
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};

  transition: all 0.1s ease-in-out;
  z-index: ${({ active, zPosteriority }) =>
    active ? zPosteriority + 550 : -1};
  ${({ type, isError }) => getSelectCss(type, !!isError)};

  button {
    display: flex;
    justify-content: space-between;
    height: 48px;
    align-items: center;
    width: 100%;
    padding: ${({ type }) =>
      type === 'underlined'
        ? '13.5px 30px 13.5px 4px'
        : '13.5px 42px 13.5px 16px'};

    top: 0;
    position: ${({ isOptionFloating }) =>
      isOptionFloating ? 'sticky' : 'static'};
    border-radius: 7px;
    z-index: 999;
    background: ${({ theme }) => theme.newColors.white};

    
    background-color: ${({ theme }) => theme.newColors.white};
    ${({ isOptionFloating, active }) =>
      isOptionFloating &&
      css`
        &:after {
          content: '';
          position: absolute;
          top: 47px;
          left: 16px;
          width: calc(100% - 32px);
          height: ${active ? '1px' : 0};
          background-color: ${({ theme }) => theme.newColors.lightGrey1};
        }
      `}};
      
    svg {
      position: absolute;
      right: ${({ type }) => (type === 'underlined' ? '0px' : '12px')};
      transition: 0.3s;

      &.chevron-down {
        transform: rotate(90deg);
      }

      &.chevron-up {
        transform: rotate(-90deg);
      }
    }
  }

  li {
    position: relative;
    color: ${({ active }) => !active && 'transparent'};
    padding: ${({ active }) => (active ? '12px 16px' : '0px')};
    height: ${({ active }) => (active ? '48px' : '0px')};
    /* &:hover {
      background-color: ${({ theme }) => theme.newColors.lightBackground1};
    } */
    z-index: 998;

    ${({ type, active }) =>
      type === 'underlined'
        ? css`
            border-style: solid;
            border-width: 0 1.5px 0 1.5px;
            border-color: ${theme.newColors.lightGrey1};
            &:last-of-type {
              border-bottom: 1px solid ${theme.newColors.lightGrey1};
            }
          `
        : css`
            &:after {
              ${active && 'content: "";'};
              position: absolute;
              bottom: 0;
              left: 16px;
              width: calc(100% - 32px);
              height: 1px;
              background-color: ${({ theme }) => theme.newColors.lightGrey1};
              &:last-of-type {
                height: 0;
              }
            }
          `}
  }
`;

const getSelectCss = (type: TSelect, error: boolean) => {
  const outlinedCss = css`
    border: 1px solid
      ${error ? theme.newColors.red : theme.newColors.lightGrey2};
    border-radius: 7px;
    background-color: ${theme.newColors.white};
  `;

  switch (type) {
    case 'outlined':
      return outlinedCss;

    case 'contained':
      return css`
        background-color: ${theme.newColors.lightBackground1};
        border-radius: 7px;
      `;

    case 'underlined':
      return css`
        background-color: ${theme.newColors.white};
        border-bottom: 1.5px solid ${theme.newColors.lightGrey2};
      `;
    default:
      // full 의 경우
      return outlinedCss;
  }
};

export default Select;
