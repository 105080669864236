import React, { memo, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import Typography from 'components/atoms/Typography';

interface IProps {
  name: string;
  placeholder?: string;
  maxLength?: number;
  inputVal: { [key: string]: string } | string;
  changeInputVal: (e: React.ChangeEvent<any>) => void;
  containerWidth?: string;
  width?: string;
  height?: string;
  message?: string;
  count?: number;
  limitCount?: number;
  minCount?: number;
  customOnBlur?: () => void;
  cssObject?: CSSProp;
}

const BasicTextareaOutlined = ({
  name,
  inputVal,
  changeInputVal,
  placeholder,
  containerWidth = '100%',
  width = '100%',
  height = '120px',
  message,
  count,
  limitCount,
  minCount,
  maxLength,
  customOnBlur,
  cssObject,
}: IProps) => {
  const [isFocus, setIsfocus] = useState(false);

  const onFocus = () => {
    setIsfocus(true);
  };

  const onBlur = () => {
    setIsfocus(false);
    customOnBlur && customOnBlur();
  };

  return (
    <TextareaContainer containerWidth={containerWidth} css={cssObject}>
      <TextArea
        name={name}
        value={typeof inputVal === 'string' ? inputVal : inputVal[name]}
        placeholder={placeholder}
        onChange={changeInputVal}
        maxLength={maxLength}
        width={width}
        height={height}
        isFocus={isFocus}
        onFocus={onFocus}
        onBlur={onBlur}
        className="typography-body2"
      />
      {(message || limitCount || minCount) && (
        <OptionalInfoSection
          isMessage={!!message}
          isCount={!!limitCount || !!minCount}
        >
          {message && (
            <Typography weight="regular" variant="caption1" color="grey1">
              {message}
            </Typography>
          )}

          {limitCount && (
            <Typography weight="regular" variant="caption1" color="grey1">
              {count} / {limitCount}
            </Typography>
          )}

          {minCount && (
            <Typography weight="regular" variant="caption1" color="grey1">
              {count} / 최소{minCount}자
            </Typography>
          )}
        </OptionalInfoSection>
      )}
    </TextareaContainer>
  );
};

export default memo(BasicTextareaOutlined);

interface ITextareaContainerStyle {
  containerWidth: string;
  css?: CSSProp;
}

interface ITextareaStyle {
  width: string;
  height: string;
  isFocus: boolean;
}

interface IOptionStyle {
  isMessage?: boolean;
  isCount?: boolean;
}

const TextareaContainer = styled.div<ITextareaContainerStyle>`
  width: ${({ containerWidth }) => containerWidth};
  ${({ css }) => css}
`;

const TextArea = styled.textarea<ITextareaStyle>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  padding: 12px 16px;
  border: 1px solid
    ${({ theme, isFocus }) =>
      isFocus ? theme.newColors.primary : theme.newColors.lightGrey2};
  border-radius: 8px;

  &::placeholder {
    color: ${({ theme }) => theme.newColors.grey1};
    /* typography-body2 */
    font-size: 14px;
    line-height: 179%;
    letter-spacing: -0.32px;
  }
`;

const OptionalInfoSection = styled.div<IOptionStyle>`
  display: flex;
  justify-content: ${({ isMessage, isCount }) =>
    isMessage && isCount
      ? `space-between`
      : isMessage && !isCount
      ? 'start'
      : !isMessage && isCount
      ? 'end'
      : ''};
  align-items: center;
  margin-top: 6px;
`;
